import React from "react";
import styled from "styled-components";
import { mixins } from "../../mixins";
import { setImageModal } from "../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../../selectors/stateSelectors";

const ImageButton = styled.button`
  border: none;
  background: none;
  outline: none !important;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
  ${mixins.ease()};

  &:hover {
    box-shadow: 0 0 0 2px rgba(56, 138, 107, 0.3);
    transform: scale(1.1);
  }

  &:focus {
    box-shadow: 0 0 0 4px rgba(56, 138, 107, 0.3);
    transform: scale(1.1);
  }

  &:focus {
    box-shadow: 0 0 0 4px rgba(56, 138, 107, 0.5);
    transform: scale(1);
  }

  img {
    display: block;
  }
`;

export const InventoryImageButton = ({ image, ...rest }) => {
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguage);

  const handleImageSelect = (item) => {
    const image = {
      url: item.full_image,
      width: item.full_image_width,
      height: item.full_image_height,
    };
    dispatch(setImageModal(image));
  };

  return (
    <ImageButton
      type="button"
      onClick={() => handleImageSelect(image)}
      {...rest}
    >
      <img height={34} width={34} alt={image.name[lang]} src={image.image} />
    </ImageButton>
  );
};
