import {Map, fromJS} from 'immutable';
import moment from "moment";
import uuid from 'uuid/v1'

const defState = Map({
    reports: [],
    selected_day: 0,
    draft: fromJS({}),
    saving: false,
});

export default function reducer(state = defState, action) {
    switch (action.type) {
        case 'CHANGE_SELECTED_REPORT_DAY': {
            let newState = state;
            newState = newState.set('selected_day', action.payload);
            return newState;
        }
        case 'SELECT_DRAFT': {
            const selectedDate = moment(new Date()).add(state.get('selected_day'), 'day');

            let newState = state;
            const report = state.get('reports').filter((report) => moment(report.get('start')).isSame(selectedDate, 'day')).first();
            if (typeof (report) !== 'undefined') {
                let newDraft = {
                    start: report.get('start'),
                    end: report.get('end'),
                    lunch_start: report.get('lunch_start'),
                    lunch_end: report.get('lunch_end'),
                    place_id: report.get('place_id'),
                };
                return newState.set('draft', fromJS(newDraft));
            }
            return state;
        }
        case 'REPORTS_LOAD_PENDING': {
            return state;
        }
        case 'REPORTS_LOAD_SUCCESS': {
            const editableReports = action.payload.map((report) => {
                return report.set('original', report);
            });
            return state.set('reports', editableReports);
        }
        case 'REPORTS_LOAD_ERROR': {
            return state;
        }
        case 'REPORT_SAVE_PENDING': {
            return state.set('saving', true);
        }
        case 'REPORT_SAVE_SUCCESS': {
            const id = action.payload.get('id');
            const index = state.get('reports').findIndex((report) => report.get('id').toString() === id.toString());
            let report = state.getIn(['reports', index]);
            if(report.get('temp')){
                report = action.payload;
            }
            report = report.set('original', action.payload);
            return state.setIn(['reports', index], report).set('saving', false);
        }
        case 'REPORT_SAVE_ERROR': {
            return state.set('saving', false);
        }
        case 'RESET_DRAFT': {
            return state.set('draft', fromJS({}));
        }
        case 'ADD_REPORT': {
            console.log(action.payload);
            const {date} = action.payload;
            const report = {
                id: uuid(),
                created_at: null,
                updated_at: null,
                temp: true,
                start: date,
            };
            return state.set('reports', state.get('reports').push(fromJS({
                ...report,
                original: report
            })));
        }
        case 'UPDATE_REPORT': {
            const {id, label, value} = action.payload;
            const index = state.get('reports').findIndex((report) => report.get('id').toString() === id.toString());
            let report = state.getIn(['reports', index]);
            switch (label) {
                case 'start': {
                    report = report.update('end', (end) => {
                        return (moment(end).isSameOrAfter(moment(value)) && typeof (end) !== 'undefined')
                            ? end
                            : value;
                    }).set('start', value);
                    break;
                }
                case 'end': {
                    report = report.update('start', (end) => {
                        return (moment(end).isSameOrBefore(moment(value)) && typeof (end) !== 'undefined')
                            ? end
                            : value;
                    }).set('end', value);
                    break;
                }
                case 'lunch_start': {
                    report = report.update('lunch_end', (end) => {
                        return (moment(end).isSameOrAfter(moment(value)) && typeof (end) !== 'undefined')
                            ? end
                            : value;
                    }).set('lunch_start', value);
                    break;
                }
                case 'lunch_end': {
                    report = report.update('lunch_start', (end) => {
                        return (moment(end).isSameOrBefore(moment(value)) && typeof (end) !== 'undefined')
                            ? end
                            : value;
                    }).set('lunch_end', value);
                    break;
                }
                default: {
                    report = report.set(label, value);
                    break;
                }
            }
            return state.setIn(['reports', index], report);
        }

        case 'REPORT_POST_SUCCESS': {
            return state.set('reports', state.get('reports').push(action.payload));
        }
        case 'REPORT_DELETE_SUCCESS': {
            //console.log(action.payload);
            const id = action.payload.get('id');
            return state.set('reports', state.get('reports').filter((report) => report.get('id').toString() !== id.toString()));
        }
        case 'REPORT_SOFT_DELETE': {
            const id = action.payload;
            return state.set('reports', state.get('reports').filter((report) => report.get('id').toString() !== id.toString()));
        }
        default:
            return state;
    }
};

/*case 'UPDATE_DRAFT': {
            const label = action.payload.label;
            const value = action.payload.value;
            let draft = state.get('draft');
            switch (label) {
                case 'start': {
                    draft = draft.update('end', (end) => {
                        return (moment(end).isSameOrAfter(moment(value)) && typeof (end) !== 'undefined')
                            ? end
                            : value;
                    }).set('start', value);
                    break;
                }
                case 'end': {
                    draft = draft.update('start', (end) => {
                        return (moment(end).isSameOrBefore(moment(value)) && typeof (end) !== 'undefined')
                            ? end
                            : value;
                    }).set('end', value);
                    break;
                }
                case 'lunch_start': {
                    draft = draft.update('lunch_end', (end) => {
                        return (moment(end).isSameOrAfter(moment(value)) && typeof (end) !== 'undefined')
                            ? end
                            : value;
                    }).set('lunch_start', value);
                    break;
                }
                case 'lunch_end': {
                    draft = draft.update('lunch_start', (end) => {
                        return (moment(end).isSameOrBefore(moment(value)) && typeof (end) !== 'undefined')
                            ? end
                            : value;
                    }).set('lunch_end', value);
                    break;
                }
                default: {
                    draft = draft.set(label, value);
                    break;
                }
            }
            return state.set('draft', draft);
        }*/