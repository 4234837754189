import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
import Layout from "./components/Layout";
import { ThemeProvider } from 'styled-components';
import mainTheme from './themes/mainTheme';

import 'moment/min/locales.min'

/* eslint-disable */
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
// pick utils
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={mainTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Layout/>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById('app'));

window.addEventListener('build', function (e) {
    console.log(e);
});