import { Iterable } from "immutable";

export const selectStock = (state) => {
  const stock = state.getIn(["stock", "stock"]);
  return Iterable.isIterable(stock) ? stock.toJS() : stock;
};

export const selectStockItem = (state, id) => {
  const stock = state.getIn(["stock", "stock"]);
  const jsStock = Iterable.isIterable(stock) ? stock.toJS() : stock;
  return jsStock.find((item) => id == item.id);
};

export const selectSelectedStock = (state) => {
  return state.getIn(["stock", "selected"]).toJS();
};

export const selectSelectedStockItem = (state, id) => {
  const selected = state.getIn(["stock", "selected"]).toJS();
  return selected.includes(id);
};

export const selectStockSuccess = (state) => {
  return state.getIn(["stock", "success"]);
};

export const selectStockError = (state) => {
  return state.getIn(["stock", "error"]);
};
