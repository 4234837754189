import React, {Component} from 'react';

import styled from 'styled-components'

const Image = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

class ImagePicker extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        return (
            <div className={this.props.className}>
                <Image>
                    <img src={this.props.image} alt={'Profile'}/>
                </Image>
            </div>
        )
    }
}

export default styled(ImagePicker)`
  display: flex;
`;