import React, {Component} from 'react';
import styled from 'styled-components';
import {bindActionCreators} from "redux";
import * as Actions from "../actions/actions";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";


const LangButton = styled.button`
    padding: 5px;
    text-transform: uppercase;
    border: none;
    background: none;
    outline: none;
    color: ${p => {
    if (p.dark) {
        return p.active ? p.theme.cTheme : p.theme.cLoginCardBackground;
    }
    if (p.transparent) {
        return p.theme.cWhite;
    } else {
        return p.active ? p.theme.cTheme : p.theme.cSecondaryText;
    }
}
};
    border-radius: ${p => p.theme.sRadius}px;
    margin: 0 5px;
    background-color: ${p => {
    if (p.dark) {
        return 'transparent';
    }
    if (p.transparent) {
        return p.active ? 'rgba(255, 255, 255, 0.3)' : 'transparent';
    } else {
        return p.active ? p.theme.cBg : 'transparent';
    }
}
};
    cursor: pointer;
    font-weight: bold;
    &:hover, &:focus{
      background-color:  ${p => {
    if (p.dark) {
        return 'transparent';
    }
    if (p.transparent) {
        return 'rgba(255, 255, 255, 0.5)';
    } else {
        return p.theme.cBg;
    }
}};
    color: ${p => p.transparent ? p.theme.cWhite : p.theme.cTheme}
}
`;

class LangMenu extends Component {
    constructor(props) {
        super(props);

        this.handleLanguage = this.handleLanguage.bind(this);
    }

    handleLanguage(language) {
        this.props.changeLanguage(language);
    }

    render() {

        const handleLanguage = this.handleLanguage;
        const {dark, transparent, selectedLanguage, noMargin} = this.props;
        const langMenu = this.props.languages.map(function (language, i) {
            return <li key={i}>
                <LangButton dark={dark} transparent={transparent} noMargin={noMargin} type={'button'}
                            onClick={() => handleLanguage(language)}
                            active={selectedLanguage === language}>
                    {language}
                </LangButton>
            </li>;
        });

        return (
            <ul className={this.props.className}>
                {langMenu}
            </ul>
        )
    }
}

function mapStateToProps(state) {
    return {
        languages: state.getIn(['state', 'languages']),
        selectedLanguage: state.getIn(['state', 'selectedLanguage']),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeLanguage: Actions.changeLanguage,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(LangMenu)`
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  margin: ${p => p.noMargin ? '0' : '1em 0'};
  li{
    display: block;  
  }
  &:first-child{
    &:before{
      display: none;
    }  
  }
`));