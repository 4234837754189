import {fromJS} from 'immutable';

const defState = fromJS({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    matching: true,
    valid: false,
    pending: false,
    unauthorized: false,
    error: false,
    success: false,
});

export default function reducer(state = defState, action) {
    switch (action.type) {
        case 'CLEAR_PASSWORDS': {
            return defState;
        }
        case 'UPDATE_PASSWORD_PENDING': {
            return state.set('pending', true)
                .set('unauthorized', false)
                .set('error', false)
                .set('success', false);
        }
        case 'UPDATE_PASSWORD_SUCCESS': {
            return state.set('pending', false)
                .set('success', true)
                .set('currentPassword', '')
                .set('newPassword', '')
                .set('newPasswordConfirm', '')
                .set('matching', true)
                .set('valid', false);
        }
        case 'UPDATE_PASSWORD_ERROR': {
            let error, unauthorized = false;
            if (action.payload.response.status === 403 || action.payload.response.status === 401) {
                unauthorized = true;
            } else {
                error = true;
            }
            return state.set('pending', false)
                .set('error', error)
                .set('unauthorized', unauthorized)
                .set('success', false);
        }
        case 'CHANGE_PASSWORD_VALUE': {
            const {key, value} = action.payload;
            const currentPassword = key === 'currentPassword' ? value : state.get('currentPassword');
            const newPassword = key === 'newPassword' ? value : state.get('newPassword');
            const newPasswordConfirm = key === 'newPasswordConfirm' ? value : state.get('newPasswordConfirm');
            const matching = newPassword === newPasswordConfirm;
            const valid = currentPassword && newPassword && newPasswordConfirm;
            return state.set(key, value)
                .set('matching', matching)
                .set('valid', valid)
                .set('unauthorized', false)
                .set('error', false)
                .set('success', false);
        }
        default:
            return state;
    }
};