import {Map} from 'immutable';

const defState = Map({
});

export default function reducer(state = defState, action) {
    switch (action.type) {
        case 'PLACES_LOAD_PENDING':{
            return state;
        }
        case 'PLACES_LOAD_SUCCESS':{
            return action.payload;
        }
        case 'PLACES_LOAD_ERROR':{
            return state;
        }
        default:
            return state;
    }
};