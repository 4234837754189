import theme from './themes/mainTheme';

import {css} from 'styled-components'

export const mixins = {
    ease: (...args) => css`
      transition-duration: ${theme.tGeneric}s;
      transition-timing-function: ${theme.tfStandard};
  `,
    pseudoToBlock: (...args) => css`
      display: block;
      content: '';
`
};