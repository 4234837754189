import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import styled from "styled-components";
import { mixins } from "../../mixins";
import Icon from "./Icon";
import { withRouter } from "react-router";

const Label = ({ children, tag, ...other }) => {
  const component = { tag: tag };

  return <component.tag {...other}>{children}</component.tag>;
};

const IconContainer = styled.div`
  margin-right: 20px;

  &.menu {
    @media (max-width: ${(p) => p.theme.sBreakpoint}px) {
      //margin: 0;
    }
  }
`;

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (typeof this.props.callback === "function" && !this.props.disabled) {
      this.props.callback();
    }
    if (typeof this.props.to !== "undefined") {
      this.setState({ redirect: true });
    }
  }

  getTag() {
    if (typeof this.props.tag !== "undefined") {
      return this.props.tag;
    } else {
      return "button";
    }
  }

  componentWillMount() {
    if (this.state.redirect) {
      this.setState({ redirect: false });
    }
  }

  componentWillUpdate() {
    if (this.state.redirect) {
      this.setState({ redirect: false });
    }
  }

  render() {
    const redirect = this.state.redirect;
    const pathname = this.props.location.pathname;
    return (
      <React.Fragment>
        {redirect && pathname !== this.props.to ? (
          <Redirect push to={this.props.to} />
        ) : (
          ""
        )}
        <Label
          tag={this.getTag()}
          onClick={this.handleClick}
          className={`${this.props.className} ${this.props.menu ? "menu" : ""}`}
        >
          {this.props.icon ? (
            <IconContainer className={this.props.menu ? "menu" : ""}>
              <Icon name={this.props.icon} />
            </IconContainer>
          ) : (
            ""
          )}
          <span>{this.props.label}</span>
        </Label>
      </React.Fragment>
    );
  }
}

export default withRouter(styled(Button)`
  box-sizing: border-box;
  border-radius: ${(p) => (p.huge ? 5 : p.theme.sRadius)}px;
  padding: ${(p) => {
    if (p.huge) {
      return 20;
    }
    if (p.small) {
      return 5;
    }
    return 12;
  }}px  ${(p) => (p.menu ? 12 : p.small ? 10 : 25)}px;
  width: ${(p) => (p.fullWidth ? "100%" : "")};
  justify-content: ${(p) => (p.alignLeft ? "flex-start" : "center")};
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  outline: none !important;
  cursor: ${(p) => (!p.disabled ? "pointer" : "default")};
  font-size: ${(p) =>
    p.huge ? p.theme.sFontButtonHuge : p.theme.sFontButton}px;
  font-weight: bold;
  color: ${(p) => {
    if (!p.secondary || p.active) {
      return p.theme.cWhite;
    } else {
      if (p.disabled) {
        return p.theme.cInactiveText;
      }
      return p.theme.cSecondaryText;
    }
  }};
  background-color: ${(p) => {
    if (p.dark) {
      if (p.disabled) {
        return p.theme.cInactiveText;
      }
      return p.theme.cThemeDark;
    } else if (!p.secondary || p.active) {
      if (p.disabled) {
        return p.theme.cInactiveText;
      }
      return p.theme.cTheme;
    } else {
      return "transpatent";
    }
  }};
  text-decoration: none !important;
  text-transform: ${(p) => (p.capitalize ? "capitalize" : "")};
  ${mixins.ease()}
  svg{
    fill:${(p) => {
      if (!p.secondary || p.active) {
        return p.theme.cWhite;
      } else {
        if (p.disabled) {
          return p.theme.cInactiveText;
        }
        return p.theme.cInactiveText;
      }
    }};
  ${mixins.ease()}
  }
  &:hover, &:focus{
    background-color: ${(p) => {
      if (!p.secondary || p.active) {
        if (p.disabled) {
          return p.theme.cInactiveText;
        }
        return p.theme.cThemeActive;
      } else {
        if (p.disabled) {
          return "transparent";
        }
        return p.theme.cTheme;
      }
    }};
    color: ${(p) => {
      if (!p.secondary || p.active) {
        return p.theme.cWhite;
      } else {
        if (p.disabled) {
          return p.theme.cInactiveText;
        }
        return p.theme.cWhite;
      }
    }};
  svg{
    fill:${(p) => {
      if (!p.secondary || p.active) {
        return p.theme.cWhite;
      } else {
        if (p.disabled) {
          return p.theme.cInactiveText;
        }
        return p.theme.cWhite;
      }
    }};
  }
  }
  
  &.menu{
    @media (max-width: ${(p) => p.theme.sBreakpoint}px){
      padding: 12px 25px;
      border-radius: 0;
      //justify-content: center;
      color: ${(p) => {
        if (p.disabled) {
          return "rgba(255, 255, 255, 0.3)";
        }
        return p.theme.cWhite;
      }};
      background-color: ${(p) => {
        if (p.active) {
          return "rgba(255, 255, 255, 0.2)";
        }
        return "transparent";
      }};
      svg{
        path{
          fill: ${(p) => p.theme.cWhite};
        }
      }
      };
      span{
        //display: none;
      }
    }
  }
`);
