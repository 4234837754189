import React, {Component} from 'react';

import styled from 'styled-components'

const Item = styled.div`
  margin-right: ${p => p.theme.sCardPadding / 2}px;
  &:last-child{
    margin-right: 0;
  }
`;

class Menu extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        let content = '';
        if (this.props.children) {
            content = this.props.children.map(function (button, key) {
                return (
                    <Item key={key}>
                        {button}
                    </Item>
                )
            });
        }
        return (
            <div className={this.props.className}>
                {content}
            </div>
        )
    }
}

export default styled(Menu)`
  padding: ${p => p.theme.sCardPadding / 2}px;
  border-radius: ${p => p.theme.sRadius}px;
  background-color: ${p => p.theme.cWhite};
  grid-column: span 12;
  display: flex;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    grid-column: span 1;
    flex-wrap: wrap;
  }
`;