import { useCallback, useEffect, useState } from "react";
import Fuse from "fuse.js";
import { useSelector } from "react-redux";
import { selectLanguage } from "../selectors/stateSelectors";
import { selectSymbolMap } from "../selectors/symbolMap";
import { selectStock } from "../selectors/stockSelectors";

export const useSortedInventory = (query, inventory, options) => {
  const lang = useSelector(selectLanguage);
  const symbolMap = useSelector(selectSymbolMap);
  const [oldQuery, setOldQuery] = useState(query);

  const [result, setResult] = useState(inventory);

  const remapSymbols = useCallback(() => {
    return oldQuery.split("").reduce((acc, current) => {
      if (symbolMap[current.toLowerCase()]) {
        return acc + symbolMap[current.toLowerCase()];
      }
      return acc + current.toLowerCase();
    }, "");
  }, [symbolMap, oldQuery]);

  const updateResult = useCallback(() => {
    const defaults = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: [
        {
          name: "normalized_name." + lang,
          weight: 0.5,
        },
        {
          name: "manufacturer",
          weight: 0.1,
        },
        {
          name: "model",
          weight: 0.2,
        },
        {
          name: "barcode",
          weight: 0.2,
        },
      ],
    };
    const fuse = new Fuse(inventory, { ...defaults, ...options });
    if (oldQuery.length > 1) {
      const normalizedQuery = remapSymbols();
      setResult(fuse.search(normalizedQuery));
    } else {
      setResult(inventory);
    }
  }, [oldQuery, inventory, lang, options, remapSymbols]);

  useEffect(() => {
    if (query !== oldQuery) {
      setOldQuery(query);
    }
  }, [query, oldQuery, setOldQuery]);

  useEffect(() => {
    updateResult();
  }, [oldQuery, updateResult]);

  useEffect(() => {
    if (!result) {
      updateResult();
    }
  }, [inventory, result, updateResult]);

  return result;
};
