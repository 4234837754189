import React, {Component} from 'react';

import styled from 'styled-components'
import Translation from "../dictionary/Translation";

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${p => p.theme.sFontSmall}px;
  margin-bottom: 10px;
  &:before{
    //margin-bottom: 2px;
    margin-right: 15px;
    display: block;
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${p => p.labelColor};
  }
`;

class Legend extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        return (
            <div className={this.props.className}>
                <LegendItem labelColor={p => p.theme.cTheme}><Translation k={'TODAYS_DATE'}/></LegendItem>
                <LegendItem labelColor={p => p.theme.cBorder}><Translation k={'REPORT_IS_SUBMITTED'}/></LegendItem>
            </div>
        )
    }
}

export default styled(Legend)`
  
`;