import React, {Component} from 'react';
import styled from 'styled-components';

class InputLabel extends Component {
    render() {
        return (
            <label {...this.props} className={this.props.className}>
                {this.props.children}
            </label>
        );
    }
}

export default styled(InputLabel)`
  font-size: ${p => p.theme.sFontTiny}px;
  text-transform: uppercase;
  color: ${p => p.theme.cInactiveText};
  font-weight: bold;
`;