import React, {Component} from 'react';
import styled from 'styled-components';

class ActionsBlock extends Component {
    render() {
        return (
            <div className={this.props.className}>
                {this.props.children}
            </div>
        );
    }
}

export default styled(ActionsBlock)`
  display: flex;
  align-items: center;
  grid-column: span 12;
  >*{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    grid-column: span 1;
      flex-wrap: wrap;
      >*{
        margin-bottom: 10px;
      }
  }
`;