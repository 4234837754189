export const persistToken = store => next => action => {

    switch (action.type) {

        case 'ON_INIT': {
            next({
                type: 'RECEIVE_TOKEN',
                payload: localStorage.getItem('TOKEN'),
            });
            break;
        }

        case 'RECEIVE_TOKEN': {
            if (store.getState().getIn(['state', 'rememberMe'])) {
                localStorage.setItem('TOKEN', action.payload);
            } else {
                next({
                    type: 'RECEIVE_TOKEN',
                    payload: action.payload,
                });
            }
            break;
        }

        default: {
            break;
        }

    }

    return next(action);
};