import {Component} from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";


class TranslationProvider extends Component {

    constructor(props) {
        super(props);

        this.getWord = this.getWord.bind(this);
    }

    getWord() {
        if (this.props.k) {
            if (this.props.dictionary[this.props.k]) {
                if (this.props.dictionary[this.props.k][this.props.language]) {
                    return this.props.dictionary[this.props.k][this.props.language];
                }
            }
            return 'db.' + this.props.k;
        }
        return '';
    }

    render() {
        if (this.props.language) {
            return this.props.render(this.getWord());
        }
        return this.props.render('');
    }
}

function mapStateToProps(state) {
    const defaultLanguage = state.getIn(['state', 'defaultLanguage']);
    const selectedLanguage = state.getIn(['state', 'selectedLanguage']);
    let language = false;
    if (selectedLanguage) {
        language = selectedLanguage;
    }
    else if (defaultLanguage) {
        language = defaultLanguage;
    }
    return {
        language: language,
        dictionary: state.getIn(['dictionary']),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(TranslationProvider));