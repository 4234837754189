import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Translation from "../dictionary/Translation";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedInStock, requestStock } from "../actions/actions";
import {
  selectStock,
  selectStockError,
  selectStockSuccess,
} from "../../selectors/stockSelectors";
import { StockTable } from "./StockTable";
import { StockSearchField } from "./StockSearchField";
import { StockRequestButton } from "./StockRequestButton";
import { useSortedInventory } from "../../hooks/sortedInventory";

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

export const StockModal = () => {
  const dispatch = useDispatch();
  // const success = useSelector(selectStockSuccess);
  // const error = useSelector(selectStockError);
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch(requestStock());
    return () => {
      dispatch(clearSelectedInStock());
    };
  }, [dispatch]);

  // if (success) {
  //   return <Redirect to={"/inventory/thankyou"} />;
  // }
  // if (error) {
  //   return <Redirect to={"/inventory/error"} />;
  // }
  return (
    <Modal
      closeLink={"/inventory"}
      fullHeight
      title={<Translation k={"INVENTORY_IN_STOCK"} />}
      leftActions={
        <Button
          secondary
          to={"/inventory/form"}
          label={<Translation k={"SEND_A_MESSAGE"} />}
        />
      }
      rightActions={
        <>
          <Button
            secondary
            to={"/inventory"}
            label={<Translation k={"CANCEL"} />}
          />
          <StockRequestButton />
        </>
      }
    >
      <StockSearchField setQueryToUse={setQuery} />
      <TableWrapper>
        <StockTable query={query} />
      </TableWrapper>
    </Modal>
  );
};
