import { fromJS, List } from "immutable";

const defState = fromJS({
  selected: [],
  message: "",
  success: false,
  error: false,
});

export default function reducer(state = defState, action) {
  switch (action.type) {
    case "STOCK_LOAD_SUCCESS": {
      return state.set("stock", action.payload);
    }
    case "TOGGLE_ITEM_IN_STOCK": {
      const id = action.payload;
      const selected = state.get("selected");
      if (selected.includes(id)) {
        const index = selected.indexOf(id);
        return state.set("selected", selected.delete(index));
      } else {
        return state.set("selected", selected.push(id));
      }
    }
    case "CLEAR_SELECTED_IN_STOCK": {
      return state.set("selected", List());
    }
    case "STOCK_MESSAGE_CHANGE": {
      return state.set("message", action.payload);
    }
    case "STOCK_MESSAGE_SUCCESS":
    case "STOCK_ORDER_SUCCESS": {
      return state.set("success", true).set("error", false);
    }
    case "STOCK_MESSAGE_ERROR":
    case "STOCK_ORDER_ERROR": {
      return state.set("success", false).set("error", true);
    }
    case "RESET_STOCK_STATUS": {
      return state.set("success", false).set("error", false);
    }
    default:
      return state;
  }
}
