import {fromJS} from 'immutable';

const defState = fromJS({
    /*inventory: [
        {
            image: 'https://dummyimage.com/24x24/545454/ffffff.png',
            name: {
                lv: 'Betona maisītājs LIV ML2130'
            },
            date_assigned: new Date(),
            manufacturer: 'Bosch',
            model: 'ML2130',
            value: 950,
            barcode: 'EAM 28493-199',
        },
        {
            image: 'https://dummyimage.com/24x24/545454/ffffff.png',
            name: {
                lv: 'C tpa leņķa slīpmašīna (125 mm)'
            },
            date_assigned: new Date(),
            manufacturer: 'Bosch',
            model: 'ML2130',
            value: 950,
            barcode: 'EAM 28493-199',
        },
        {
            image: 'https://dummyimage.com/24x24/545454/ffffff.png',
            name: {
                lv: 'Gāzes gaisa sildītājs KID 30M'
            },
            date_assigned: new Date(),
            manufacturer: 'Bosch',
            model: 'ML2130',
            value: 950,
            barcode: 'EAM 28493-199',
        },
        {
            image: 'https://dummyimage.com/24x24/545454/ffffff.png',
            name: {
                lv: 'Sildītājs Bulla 177'
            },
            date_assigned: new Date(),
            manufacturer: 'Vagner',
            model: 'MK928',
            value: 299.80,
            barcode: '11009474',
        },
    ]*/
});

export default function reducer(state = defState, action) {
    //console.log(state);
    switch (action.type) {
        /*case 'CHANGE_PROFILE_Email':{
            return state.set('login', action.payload);
        }
        case 'CHANGE_PROFILE_LOGIN':{
            return state.set('login', action.payload);
        }
        case 'CHANGE_PROFILE_BIRTH_DATE':{
            return state.set('birth_date', action.payload);
        }*/
        case 'PROFILE_FIELD_POST_PENDING': {
            return state;
        }
        case 'PROFILE_FIELD_POST_SUCCESS': {
            //console.log(action.payload);
            return state.merge(action.payload);
        }
        case 'PROFILE_FIELD_POST_ERROR': {
            return state;
        }
        case 'USER_LOAD_SUCCESS': {
            return state.merge(action.payload);
            //!! reset !!
        }
        default:
            return state;
    }
};