import { combineReducers } from "redux-immutable";
import profile from "./profileReducer";
import statistics from "./statisticsReducer";
import state from "./stateReducer";
import reports from "./reportsReducer";
import places from "./placesReducer";
import calendar from "./calednarReducer";
import dictionary from "./dictionaryReducer";
import password from "./passwordReducer";
import stock from "./stockReducer";
import symbolMap from "./symbolMapReducer";
import imageModal from "./imageModalReducer";

export default combineReducers({
  profile,
  statistics,
  state,
  reports,
  places,
  calendar,
  dictionary,
  password,
  stock,
  symbolMap,
  imageModal,
});
