import {fromJS} from 'immutable';

const defState = fromJS({
    position: {
        x: 0,
        y: 0,
    },
});

export default function reducer(state = defState, action) {
    switch (action.type) {
        case 'CALENDAR_POPOVER_OPEN':{
            break;
        }
        default:
            return state;
    }
};