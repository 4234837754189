import React, { useEffect, useState } from "react";
import SearchField from "../common/SearchField";
import { useDebounce } from "../../hooks/debounce";

export const StockSearchField = ({ setQueryToUse }) => {
  const [query, setQuery] = useState("");

  const throttledQuery = useDebounce(query, 300);

  useEffect(() => {
    setQueryToUse(throttledQuery);
  }, [throttledQuery, setQueryToUse]);

  return (
    <SearchField
      placeholder={"SEARCH_INVENTORY"}
      clear={() => setQuery("")}
      query={query}
      onChange={(e) => setQuery(e.target.value)}
    />
  );
};
