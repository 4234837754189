import {fromJS} from 'immutable';

const defState = fromJS({
});

export default function reducer(state = defState, action) {
    switch (action.type) {
        case 'STATISTICS_LOAD_PENDING':{
            return state;
        }
        case 'STATISTICS_LOAD_SUCCESS':{
            return action.payload;
        }
        case 'STATISTICS_LOAD_ERROR':{
            return state;
        }
        default:
            return state;
    }
};