import React from "react";
import styled from "styled-components";
import Modal from "../common/Modal";
import Translation from "../dictionary/Translation";
import Button from "../common/Button";

const Infotext = styled.p`
  font-size: ${(p) => p.theme.sFontSmall}px;
  color: ${(p) => p.theme.cWeekend};
  font-weight: bold;
  margin-bottom: 20px;
`;

export const StockError = () => (
  <Modal
    closeLink={"/inventory"}
    small
    title={<Translation k={"SOMETHING_WENT_WRONG"} />}
    rightActions={
      <React.Fragment>
        <Button to={"/inventory"} label={<Translation k={"CLOSE"} />} />
      </React.Fragment>
    }
  >
    <Infotext>
      <Translation k={"SOMETHING_WENT_WRONG_TEXT"} />
    </Infotext>
  </Modal>
);
