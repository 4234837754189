import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Modal from "../common/Modal";
import Translation from "../dictionary/Translation";
import Button from "../common/Button";
import * as Actions from "../actions/actions";

const InfoText = styled.p`
  font-size: ${(p) => p.theme.sFontSmall}px;
  color: ${(p) => p.theme.cTheme};
  font-weight: bold;
  margin-bottom: 20px;
`;

export const StockThankYou = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.resetStockStatus());
  }, [dispatch]);

  return (
    <Modal
      closeLink={"/inventory"}
      small
      title={<Translation k={"THANK_YOU"} />}
      rightActions={
        <React.Fragment>
          <Button to={"/inventory"} label={<Translation k={"CLOSE"} />} />
        </React.Fragment>
      }
    >
      <InfoText>
        <Translation k={"THANK_YOU_TEXT"} />
      </InfoText>
    </Modal>
  );
};
