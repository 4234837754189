import React, {Component} from 'react';
import TranslationProvider from "./TranslationProvider";


class Translation extends Component {
    render() {
        return <TranslationProvider k={this.props.k} render={translation => translation}/>
    }
}

export default Translation;