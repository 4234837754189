import React from "react";

import styled from 'styled-components';
import moment from 'moment';
import Day from "./Day";

const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-area: dates;
  justify-items: center;
`;

const Days = styled.div`
  grid-area: days;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  font-weight: bold;
`;


class Calendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentMonth: moment(new Date()).add(this.props.selectedMonth, "month"),
        };

        this.renderCells = this.renderCells.bind(this);
        this.renderDays = this.renderDays.bind(this);
    }

    renderDays() {
        const dateFormat = "dd";
        const days = [];
        let startDate = moment(this.props.month).locale(this.props.language).weekday(0);
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {moment(startDate).format(dateFormat)}
                </div>
            );
            startDate = moment(startDate).add(1, 'days');
        }
        return <Days>{days}</Days>;
    }

    renderCells() {
        const currentDate = moment(new Date()).locale(this.props.language);
        const monthStart = moment(this.props.month).locale(this.props.language).startOf('month');
        const monthEnd = moment(this.props.month).locale(this.props.language).endOf('month');
        const startDate = moment(monthStart).locale(this.props.language).startOf('week');
        const endDate = moment(monthEnd).locale(this.props.language).startOf('week');
        const getReports = (day) => {
            const reports = this.props.reports.filter((report) => {
                return moment(report.start).isSame(day, 'day');
            });
            if (reports.length) {
                return reports;
            }
            return false;
        };

        const dateFormat = "D";
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = day.format(dateFormat);
                const current = day.isSame(currentDate, 'day');
                if (moment(this.props.month).isSame(day, 'month')) {
                    days.push(
                        <Day key={day} current={current} label={formattedDate}
                             weekend={day.day() === 6 || day.day() === 0} reports={getReports(day)}
                             eventTypes={["click", "touchend"]}/>
                    );
                }
                else {
                    days.push(<div key={day}/>);
                }
                day = day.add(1, 'day');
            }
        }
        return <Body>{days}</Body>;
    }

    onDateClick = day => {
    };

    render() {
        //console.log(this.props.reports);
        return (
            <div>
                <div className={this.props.className}>
                    {this.renderDays()}
                    {this.renderCells()}
                </div>
            </div>
        );
    }
}

export default styled(Calendar)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: '. days''weeks dates';
  grid-row-gap: 20px;
`;