import React, { useCallback, useEffect, useMemo } from "react";
import Loader from "../common/Loader";
import uuid from "uuid/v1";
import Translation from "../dictionary/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLanguage,
  selectStockLoading,
} from "../../selectors/stateSelectors";
import styled from "styled-components";
import { mixins } from "../../mixins";
import { toggleItemInStock } from "../actions/actions";
import {
  selectSelectedStock,
  selectSelectedStockItem,
  selectStock,
  selectStockItem,
} from "../../selectors/stockSelectors";
import { InventoryImageButton } from "./InventoryImageButton";
import { useSortedInventory } from "../../hooks/sortedInventory";

const Table = styled.table`
  width: 100%;
  vertical-align: middle;
  border: none;
  border-collapse: collapse;
  font-size: ${(p) => p.theme.sFontSmall}px;
  img {
    //height: 24px;
    //width: 24px;
  }
  tr {
    ${mixins.ease()};
    border-radius: 3px;
  }
  tr.disabled {
    color: ${(p) => p.theme.cInactiveText};
    img {
      opacity: 0.7;
    }
  }
  tbody tr:hover {
    background-color: ${(p) => p.theme.cInput};
  }
  tbody tr.disabled:hover {
    background-color: transparent;
  }
  th,
  td {
    padding-left: 24px;
    &:first-child {
      padding-left: 5px;
    }
  }
  td {
    padding-top: 3px;
    padding-bottom: 3px;
    vertical-align: middle;
  }
  td.small {
    min-width: 80px;
  }
  td.long {
    min-width: 220px;
  }
`;

const AddButton = styled.div`
  position: relative;
  input {
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearence: none;
    appearence: none;
    position: absolute;
    height: 1px;
    width: 1px;
    border: none;
    background: none;
  }
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    cursor: pointer;
    border: 2px solid ${(p) => p.theme.cBorder};
    ${mixins.ease()};
    &:after {
      ${mixins.pseudoToBlock()};
      border: 0;
      height: 10px;
      width: 5px;
      border-right: 2px solid ${(p) => p.theme.cWhite};
      border-bottom: 2px solid ${(p) => p.theme.cWhite};
      transform: rotate(45deg);
      margin-top: -4px;
      margin-left: 1px;
      opacity: 0;
    }
  }
  input:disabled + label {
    background-color: ${(p) => p.theme.cBorder};
    cursor: not-allowed;
  }
  input:checked + label {
    background-color: ${(p) => p.theme.cSecondaryText};
    border-color: ${(p) => p.theme.cSecondaryText};
    &:after {
      opacity: 1;
    }
  }
`;

const CheckBox = ({ id, disabled }) => {
  const dispatch = useDispatch();
  const isSelected = useSelector((state) => selectSelectedStockItem(state, id));

  const handleSelect = useCallback(() => {
    dispatch(toggleItemInStock(id));
  }, [id]);

  const uid = "checkbox-" + uuid();
  return useMemo(
    () => (
      <AddButton>
        <input
          id={uid}
          type={"checkbox"}
          checked={isSelected}
          onChange={handleSelect}
          disabled={disabled}
        />
        <label htmlFor={uid} />
      </AddButton>
    ),
    [id, disabled, handleSelect, uid]
  );
};

const TableItem = ({ item }) => {
  const lang = useSelector(selectLanguage);

  return useMemo(
    () => (
      <tr className={!item.available ? "disabled" : ""}>
        <td>
          <CheckBox disabled={!item.available} id={item.id} />
        </td>
        <td>
          <InventoryImageButton image={item} />
        </td>
        <td className={"long"}>{item.name[lang]}</td>
        <td className={"small"}>{item.barcode}</td>
        {/*<td className={"small"}>
          {item.available ? (
            <Translation k={"AVAILABLE"} />
          ) : (
            <Translation k={"NOT_AVAILABLE"} />
          )}
        </td>*/}
      </tr>
    ),
    [item.id]
  );
};

export const StockTable = ({ query }) => {
  const stockLoading = useSelector(selectStockLoading);
  const inventory = useSelector(selectStock);
  const searched = useSortedInventory(query, inventory);

  return useMemo(() => {
    if (stockLoading || !searched) {
      return <Loader />;
    }
    return (
      <Table>
        <tbody>
          {searched.map((item) => (
            <TableItem key={item.id} item={item} />
          ))}
        </tbody>
      </Table>
    );
  }, [stockLoading, searched]);
};
