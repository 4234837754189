import React, {Component} from 'react';

import styled from 'styled-components'
import {mixins} from "../mixins";
import logo from '../logo.svg'
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../to-js";
import LangMenu from "./common/LangMenu";
import * as Actions from "./actions/actions";

const Logo = styled.img`
  width: 100px;
`;

const ProfileButton = styled.div`
  padding: 0 30px;
  font-size: ${p => p.theme.sFontButton}px;
  font-weight: bold;
  display: flex;
  align-items: center;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    padding: 0;
    display: none;
  }
`;

const ProfileName = styled.div`
  color: ${p => p.theme.cBlack};
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    display: none;
  }
`;

const ProfileEmail = styled.div`
  color: ${p => p.theme.cInactiveText};
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    display: none;
  }
`;

const LangMenuContainer = styled.div`
  display: flex;
  align-items: center;
  &:before{
    display: block;
    content: '';
    margin-right: 15px;
    height: 20px;
    width: 2px;
    background-color: ${p => p.theme.cBg};
  }
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    display: none;
  }
`;

const ProfilePicture = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    margin-right: 0;
  }
`;

const Wing = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Burger = styled.button`
  background: 0;
  padding: 0;
  border: none;
  outline: none;
  height: 22px;
  width: 27px;
  position: relative;
  display: none;
  cursor: pointer;
  
  span{
   position: absolute;
   display: block;
   width: 100%;
   width: calc(100% - 7px);
   height: 2px;
   top: 10px;    
   right: 0;
   background-color: ${p => p.theme.cBlack};
   
   
   &:first-child{
     top: 0;
     width: 100%;
   }
   &:last-child{
     top: 20px;
     width: 100%;
   }
  }
  
  &:after{
    ${mixins.pseudoToBlock()};
    position: absolute;
    left: -15px;
    right: -15px;
    top: -15px;
    bottom: -15px;
  }
  
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    display: block;
  }
`;

class Header extends Component {

    render() {
        const {className, profile, setMenuState} = this.props;

        return (
            <div className={className}>
                <Wing>
                    <Link to={'/'}><Logo src={logo}/></Link>
                </Wing>
                <Wing>
                    {profile.id ? <Link to={'/profile'}>
                        <ProfileButton>
                            <ProfilePicture>
                                <img src={profile['image']} alt={'Profile'}/>
                            </ProfilePicture>
                            <div>
                                <ProfileName>{profile['first_name'] + ' ' + profile['last_name']}</ProfileName>
                                <ProfileEmail>{profile['email']}</ProfileEmail>
                            </div>
                        </ProfileButton>
                    </Link> : ''}
                    <LangMenuContainer><LangMenu/></LangMenuContainer>
                    <Burger onClick={() => setMenuState(true)}>
                        <span/>
                        <span/>
                        <span/>
                    </Burger>
                </Wing>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.get('profile'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setMenuState: Actions.setMenuState,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(Header)`
  grid-area: h;
  border-bottom: 1px solid ${p => p.theme.cBorder};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  
  
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 0 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
`));