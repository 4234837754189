import React, {Component} from 'react';
import styled from 'styled-components';
import {ReactComponent as Profile} from '../../img/icons/profile.svg';
import {ReactComponent as Summary} from '../../img/icons/summary.svg';
import {ReactComponent as Report} from '../../img/icons/report.svg';
import {ReactComponent as Calendar} from '../../img/icons/calendar.svg';
import {ReactComponent as Bus} from '../../img/icons/bus.svg';
import {ReactComponent as Key} from '../../img/icons/key.svg';
import {ReactComponent as LogOut} from '../../img/icons/logout.svg';
import {ReactComponent as Gear} from '../../img/icons/gear.svg';
import {ReactComponent as Add} from '../../img/icons/add.svg';
import {ReactComponent as Inventory} from '../../img/icons/inventory.svg';

const Container = styled.div`
  height: 24px;
  width: 24px;
  svg{
    height: 100%;
    display: block;
  }
  path{
    fill: ${p => p.color};
    //stroke: ${p => p.color} !important;
    stroke: transparent;
  }
`;

class Icon extends Component {
    constructor(props) {
        super(props);

        this.getIcon = this.getIcon.bind(this);
    }

    getIcon() {
        switch (this.props.name) {
            case 'profile': {
                return <Profile/>;
            }
            case 'summary': {
                return <Summary/>;
            }
            case 'report': {
                return <Report/>;
            }
            case 'calendar': {
                return <Calendar/>;
            }
            case 'bus': {
                return <Bus/>;
            }
            case 'logout': {
                return <LogOut/>;
            }
            case 'key': {
                return <Key/>;
            }
            case 'gear': {
                return <Gear/>;
            }
            case 'add': {
                return <Add/>;
            }
            case 'inventory': {
                return <Inventory/>;
            }
            default: {
                return '';
            }
        }
    }

    render() {
        return (
            <Container color={this.props.color}>
                {this.getIcon()}
            </Container>
        );
    }
}

export default Icon;