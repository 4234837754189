import React from 'react'
import styled from 'styled-components'
import {Link} from "react-router-dom";
import {mixins} from "../../mixins";
import H from "../typography/H";

const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 10px;
  z-index: 200;
  box-sizing: border-box;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    padding: 0;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const OverlayLink = styled(Link)`
  position: absolute;
  background-color: ${p => p.theme.cBlack};
  opacity: 0.8;
  height: 100%;
  width: 100%;
`;

const Body = styled.div`
  //align-self: stretch;
  align-self: ${p => p.fullHeight ? 'stretch' : 'center'};
  max-height: 100%;
  width: 100%;
  max-width: ${p => p.small ? '430' : '640'}px;
  background-color: ${p => p.theme.cWhite};
  z-index: 200;
  border-radius: 5px;
  padding: 30px;
  position: relative;
  min-width: 0;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    border-radius: 0;
    max-width: none;
    padding: 30px 10px;
  }
  >*{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const CloseButton = styled(Link)`
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  ${mixins.ease()};
  right: 10px;
  top: 10px;
  
  &:before, &:after{
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 2px;
    background-color: ${p => p.theme.cBlack};
    width: 20px;
    margin-left: -10px;
    margin-top: -1px;
    border-radius: 1px;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
  &:hover, &:focus{
    background-color: ${p => p.theme.cInput};
  }
`;

const ActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    display: block;
  }
`;

const Wing = styled.div`
  display: flex;
  >*{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    justify-content: space-between;
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
`;

function Content(props) {
    if(props.form){
        return <form onSubmit={props.onSubmit}>
            {props.children}
        </form>
    }
    else{
        return <div>
            {props.children}
        </div>
    }
}

export default function Modal(props) {
    return (
        <Container tabIndex={0}>
            <OverlayLink to={props.closeLink}/>
            <Body fullHeight={props.fullHeight} small={props.small}>
            <CloseButton to={props.closeLink}/>
            <Content form={props.form} onSubmit={props.onSubmit}>
                <Title>
                    <H size={2} styleSize={3}>{props.title}</H>
                </Title>
                {/*Content*/}
                {props.children}
                {/*End Content*/}
                <ActionsBar>
                    <Wing>
                        {props.leftActions}
                    </Wing>
                    <Wing>
                        {props.rightActions}
                    </Wing>
                </ActionsBar>
            </Content>
            </Body>
        </Container>
    );
}