import React, {Component} from 'react';

import styled from 'styled-components'

const Label = styled.div`
  font-size: ${p => p.theme.sFontTiny}px;
  text-transform: uppercase;
  color: ${p => p.theme.cInactiveText};
  font-weight: bold;
`;

const Value = styled.div`
  font-size: ${p => !p.smallText ? p.theme.sFontHuge : p.theme.sFontButton}px;
  font-weight: ${p => !p.smallText ? 'bold' : 'normal'};
  cursor: ${p => typeof(p.callback) === 'function' ? 'pointer' : ''};
  padding-top: ${p => p.smallText ? '7' : 0}px;
`;

class StatisticsBlock extends Component {
    constructor(props) {
        super(props);

        this.getValue = this.getValue.bind(this)
    }

    getValue() {

    }

    render() {
        return (
            <div className={this.props.className}>
                <Label>
                    {this.props.label}
                </Label>
                <Value smallText={this.props.smallText} callback={this.props.callback} onClick={typeof(this.props.callback) === 'function' ? () => {
                    this.props.callback();
                } : () => {
                }}>
                    {this.props.value}
                </Value>
            </div>
        )
    }
}

export default styled(StatisticsBlock)`
  text-align: ${p => p.reversed ? 'right' : ''};
  margin-right: ${p => p.reversed ? 0 : '30px'};
  margin-left: ${p => !p.reversed ? 0 : '30px'};
  &:last-child{
    margin-right: ${p => p.reversed ? 0 : 0};
  }
`;