import { sentStockOrder } from "../actions/actions";
import React from "react";
import Translation from "../dictionary/Translation";
import Button from "../common/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedStock } from "../../selectors/stockSelectors";

export const StockRequestButton = () => {
  const dispatch = useDispatch();
  const selected = useSelector(selectSelectedStock);
  return (
    <Button
      disabled={!selected.length}
      callback={() => dispatch(sentStockOrder({ inventory: selected }))}
      label={
        <React.Fragment>
          <Translation k={"REQUEST"} />
          {selected.length ? " (" + selected.length + ")" : ""}
        </React.Fragment>
      }
    />
  );
};

