import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';

const keyFrames = keyframes`
  0% {
    transform: none;
    animation-timing-function: cubic-bezier(.6,0,.9,1);
  }
  50% {
    transform: scale(0.5);
    animation-timing-function: cubic-bezier(.1, 0, 0.4, 1);
  }
  100% {
    transform: none;
    animation-timing-function: cubic-bezier(.6,0,.9,1);
  }
`;

class Loader extends Component {
    render() {
        return (
            <div className={this.props.className}>
                <span/>
                <span/>
                <span/>
            </div>
        )
    }
}

export default styled(Loader)`
  margin: auto;
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span{
    display: block;
    height: 20px;
    width: 20px;
    background-color: ${p => p.theme.cTheme};  
    border-radius: 50%;
    animation: ${keyFrames} 0.8s cubic-bezier(.6,0,.9,1) 0s infinite;
    &:nth-child(1){
      animation-delay: -0.16s;
    }
    &:nth-child(2){
      animation-delay: -0.08s;    
    }
  }
`;