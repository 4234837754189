export default {

    //layout
    sRadius: 3,

    sCardPadding: 20,

    sBreakpoint: 768,

    //font
    sFontRegular: 14,
    sFontButton: 13,
    sFontButtonHuge: 16,
    sFontSmall: 12,
    sFontTiny: 11,
    sFontHuge: 24,

    sH1: 28,
    sH2: 20,

    //color
    cWhite: '#ffffff',
    cBlack: '#000000',
    cBorder: '#e6e6e6',
    cDateHover: '#cccccc',
    cInput: '#f0f0f0',
    cSecondaryText: '#6f6f6f',
    cInactiveText: '#b6b6b6',
    cTheme: '#388a6b',
    cThemeDark: '#27674f',
    cThemeActive: '#30775c',
    cWeekend: '#d16f80',
    cWeekendBg: '#efcbd2',
    cBg: '#f0f0f0',
    cLoginBg: '#949ba6',
    cLoginCardBackground: '#434655',
    cLoginInput: '#686c80',
    cLoginBorder: '#595c6e',

    //time
    tGeneric: 0.2,

    //timing functions
    tfStandard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',

};