import React, {Component} from 'react';

import styled from 'styled-components'

class CardGrid extends Component {
    /*constructor(props) {
        super(props);
    }
*/
    render() {
        return (
            <div className={this.props.className}>
                {this.props.children}
            </div>
        )
    }
}

export default styled(CardGrid)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    grid-template-columns: none;
    grid-gap: 10px;
  }
`;