import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import PageTitle from "../common/PageTitle";
import CardGrid from "../common/CardGrid";

import { requestUser } from "../actions/actions";
import Translation from "../dictionary/Translation";
import Loader from "../common/Loader";
import Card from "../common/Card";
import { mixins } from "../../mixins";

import ActionsBlock from "../common/ActionsBlock";
import Button from "../common/Button";
import SearchField from "../common/SearchField";
import { selectProfile } from "../../selectors/profileSelectors";
import {
  selectLanguage,
  selectProfileLoading,
} from "../../selectors/stateSelectors";
import { useSortedInventory } from "../../hooks/sortedInventory";
import { InventoryImageButton } from "../stock/InventoryImageButton";

const TableContainer = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
  vertical-align: middle;
  border: none;
  border-collapse: collapse;
  font-size: ${(p) => p.theme.sFontSmall}px;
  tr {
    ${mixins.ease()};
  }
  tbody tr:hover {
    background-color: ${(p) => p.theme.cInput};
  }
  th {
    text-align: left;
    color: ${(p) => p.theme.cInactiveText};
    font-weight: bold;
    padding-bottom: 25px;
    position: relative;
    border: none;
    &:after {
      display: block;
      content: "";
      position: absolute;
      height: 2px;
      bottom: 10px;
      width: 100%;
      left: 0;
      background-color: ${(p) => p.theme.cBorder};
    }
  }
  th,
  td {
    padding-left: 24px;
  }
  td {
    padding-top: 3px;
    padding-bottom: 3px;
    vertical-align: middle;
  }
  td.small {
    min-width: 80px;
  }
  td.long {
    min-width: 220px;
  }
`;

export const Inventory = ({ className }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const { inventory } = useSelector(selectProfile);
  const profileLoading = useSelector(selectProfileLoading);
  const lang = useSelector(selectLanguage);
  const sortedInventory = useSortedInventory(query, inventory);

  useEffect(() => {
    dispatch(requestUser());
  }, [dispatch]);

  const getTable = () => {
    function getRows() {
      return sortedInventory.map((item, key) => {
        return (
          <tr key={key}>
            <td>
              <InventoryImageButton image={item} />
            </td>
            <td className={"long"}>{item.name[lang]}</td>
            {/*<td className={'small'}>{moment(item.date_assigned).format('DD.MM.YYYY')}</td>*/}
            <td className={"small"}>{item.manufacturer}</td>
            <td className={"small"}>{item.model}</td>
            <td className={"small"}>{"€" + item.value}</td>
            <td className={"small"}>{item.barcode}</td>
          </tr>
        );
      });
    }

    return (
      <Table>
        <thead>
          <tr>
            <th>
              <Translation k={"IMAGE"} />
            </th>
            <th>
              <Translation k={"ITEM_NAME"} />
            </th>
            {/*<th><Translation k={'DATE_ASSIGNED'}/></th>*/}
            <th>
              <Translation k={"MANUFACTURER"} />
            </th>
            <th>
              <Translation k={"MODEL"} />
            </th>
            <th>
              <Translation k={"VALUE"} />
            </th>
            <th>
              <Translation k={"BARCODE"} />
            </th>
          </tr>
        </thead>
        <tbody>{getRows()}</tbody>
      </Table>
    );
  };

  if (profileLoading || !sortedInventory) {
    return <Loader />;
  } else {
    return (
      <div className={className}>
        <PageTitle>
          <Translation k={"MY_INVENTORY"} />
        </PageTitle>
        <CardGrid>
          <Card span={12}>
            {inventory && inventory.length ? (
              <>
                <SearchField
                  placeholder={"SEARCH_INVENTORY"}
                  clear={() => setQuery("")}
                  query={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <TableContainer>{getTable()}</TableContainer>
              </>
            ) : (
              <Translation k={"INVENTORY_IS_EMPTY"} />
            )}
          </Card>
          <ActionsBlock>
            <Button
              to={"/inventory/stock"}
              label={<Translation k={"SEARCH_IN_STOCK"} />}
            />
          </ActionsBlock>
        </CardGrid>
      </div>
    );
  }
};
