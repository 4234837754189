import React, {Component} from 'react';
import styled from 'styled-components';
import TranslationProvider from "../dictionary/TranslationProvider";
import Input from "./Input";
import {mixins} from "../../mixins";

const Container = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const CancelButton = styled.button`
  position: absolute;
  width: 40px;
  height: 100%;
  right: 0;
  top: 0;
  background: none;
  border: none;
  cursor: pointer;
  &:before, &:after{
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 2px;
    background-color: ${p => p.theme.cSecondaryText};
    width: 20px;
    margin-left: -10px;
    margin-top: -1px;
    border-radius: 1px;
    ${mixins.ease()};
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
  &:hover, &:focus{
    &:before, &:after{
      background-color: ${p => p.theme.cTheme};
    }
  }
`;

export default class SearchField extends Component {
    render() {
        return (
            <Container>
                <TranslationProvider k={this.props.placeholder} render={(translation) => {
                    return <Input placeholder={translation} onChange={this.props.onChange}
                                  value={this.props.query} onKeyDown={(e) => {
                        if (e.keyCode === 27) this.props.clear();
                    }}/>
                }}/>
                {this.props.query ? <CancelButton onClick={this.props.clear}/> : ''}
            </Container>
        );
    }
}