import { fromJS } from "immutable";

const defState = fromJS(null);

export default function reducer(state = defState, action) {
  switch (action.type) {
    case "SET_MODAL_IMAGE": {
      return fromJS(action.payload);
    }
    default:
      return state;
  }
}
