import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import Card from "../common/Card";
import PageTitle from "../common/PageTitle";
import CardGrid from "../common/CardGrid";
import Menu from "../common/Menu";
import TimePicker from "material-ui-pickers/TimePicker";
import Button from "../common/Button";
import StatisticsBlock from "../common/StatisticsBlock";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { toJS } from "../../to-js";
import * as Actions from "../actions/actions";
import Translation from "../dictionary/Translation";
import Loader from "../common/Loader";
import SiteCard from "../common/SiteCard";
import deepEqual from "deep-equal";
import ActionsBlock from "../common/ActionsBlock";
import TranslationProvider from "../dictionary/TranslationProvider";
import Textarea from "../common/Textarea";

const TimeBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: false,
      label: false,
    };

    this.picker = React.createRef();
    this.getOptions = this.getOptions.bind(this);
    this.clearLunch = this.clearLunch.bind(this);
  }

  openPicker = (report, label) => {
    this.setState({
      report: report,
      label: label,
    });
    this.picker.open();
  };

  handleTimeChange = (e, report, label) => {
    const time =
      moment(this.props.selectedDate).format("YYYY-MM-DD") +
      " " +
      moment(e).format("HH:mm:ss");
    this.props.updateReport(report.id, label, time);
  };

  getMenu = () => {
    let day = moment(new Date()).add(-2, "day");
    const buttons = [];
    const labels = [
      <Translation k={"THE_DAY_BEFORE_YESTERDAY"} />,
      <Translation k={"YESTERDAY"} />,
      <Translation k={"TODAY"} />,
    ];
    for (let i = -2; i <= 0; i++) {
      const callback = () => {
        this.props.changeDay(i);
      };
      const label = labels[i + 2];
      const isActive = this.props.selectedDay === i;
      buttons.push(
        <Button
          callback={callback}
          capitalize
          label={label}
          secondary={true}
          key={i}
          active={isActive}
        />
      );
      day.add(1, "day");
    }
    return buttons;
  };

  isDirty = (report) => {
    const getCurrent = () => {
      let { original, updated_at, created_at, ...current } = report;
      return current;
    };
    const getOriginal = () => {
      const { updated_at, created_at, ...original } = report.original;
      return original;
    };
    return !deepEqual(getCurrent(), getOriginal());
  };

  isValid = (report) => {
    return !!report.end && !!report.start && !!report.place_id;
  };

  getReports = () => {
    const day = this.props.selectedDate;
    const reports = this.props.reports.filter((report) => {
      return moment(report.start).isSame(day, "day");
    });
    if (reports.length) {
      return reports;
    }
    return [];
  };

  getId() {
    const day = this.props.selectedDate;
    const report = this.props.reports.filter((report) => {
      return moment(report.start).isSame(day, "day");
    })[0];
    if (typeof report !== "undefined") {
      return report.id;
    }
    return false;
  }

  getPlace = (place_id) => {
    if (place_id) {
      return this.props.places.find(
        (place) => place.id.toString() === place_id.toString()
      );
    }
    return false;
  };

  handlePostReport = (report, id) => {
    if (this.isValid(report)) {
      if (id) {
        this.props.saveReport(id, report);
      } else {
        this.props.postReport(report);
      }
    }
  };

  componentWillMount() {
    this.props.loadReports();
  }

  getTimeString = (report, label) => {
    const value = report[label];
    if (typeof value !== "undefined" && value) {
      return moment(value).format("HH:mm");
    }
    return "--:--";
  };

  getOptions = () => {
    if (typeof this.props.places !== "undefined" && this.props.places.length) {
      return this.props.places.map((place, key) => {
        if (place.status) {
          return (
            <option value={place.id.toString()} key={key}>
              {place.title}
            </option>
          );
        } else {
          return "";
        }
      });
    }
  };

  handleDelete = (report) => {
    if (report.temp) {
      this.props.softDeleteReport(report.id);
    } else {
      this.props.deleteReport(report.id);
    }
  };

  clearLunch = (report) => {
    this.props.updateReport(report, "lunch_start", null);
    this.props.updateReport(report, "lunch_end", null);
  };

  render() {
    if (!(!this.props.reportsLoading && !this.props.sitesLoading)) {
      return <Loader />;
    } else {
      const reports = this.getReports();
      const getTime = (report, label) => {
        let time = moment(new Date()).toISOString();
        let draftTime = this.getTimeString(report, label);
        if (draftTime !== "--:--") {
          draftTime = moment(
            moment(new Date()).format("YYYY-MM-DD") + " " + draftTime
          );
          time = draftTime.toISOString();
        }
        return time;
      };

      const reportsToRender = reports.map((report, key) => {
        const id = report.id;
        console.log(report);
        const isLunchTime =
          this.getTimeString(report, "lunch_start") !== "--:--" ||
          this.getTimeString(report, "lunch_end") !== "--:--";
        return (
          <React.Fragment key={key}>
            <Card title={<Translation k={"WORK_BEGINNING_AND_END"} />} span={4}>
              <TimeBlock>
                <StatisticsBlock
                  label={<Translation k={"BEGINNING"} />}
                  value={this.getTimeString(report, "start")}
                  callback={() => {
                    this.openPicker(report, "start");
                  }}
                />
                <StatisticsBlock
                  label={<Translation k={"END"} />}
                  value={this.getTimeString(report, "end")}
                  callback={() => {
                    this.openPicker(report, "end");
                  }}
                />
              </TimeBlock>
            </Card>
            <Card
              close={isLunchTime ? () => this.clearLunch(report) : false}
              title={<Translation k={"LUNCH"} />}
              span={4}
            >
              <TimeBlock>
                <StatisticsBlock
                  label={<Translation k={"BEGINNING"} />}
                  value={this.getTimeString(report, "lunch_start")}
                  callback={() => {
                    this.openPicker(report, "lunch_start");
                  }}
                />
                <StatisticsBlock
                  label={<Translation k={"END"} />}
                  value={this.getTimeString(report, "lunch_end")}
                  callback={() => {
                    this.openPicker(report, "lunch_end");
                  }}
                />
              </TimeBlock>
            </Card>
            <Card title={<Translation k={"DATE"} />} span={4}>
              <StatisticsBlock
                label={<Translation k={"DATE"} />}
                value={this.props.selectedDate.format("DD.MM.YYYY")}
              />
            </Card>
            <Card title={<Translation k={"SITE"} />} span={12}>
              <SiteCard
                place={this.getPlace(report.place_id)}
                select={
                  <select
                    value={(report.place_id ? report.place_id : "").toString()}
                    onChange={(event) =>
                      this.props.updateReport(
                        report,
                        "place_id",
                        event.target.value
                      )
                    }
                  >
                    <TranslationProvider
                      k="SELECT_SITE"
                      render={(text) => <option value={""}>{text}</option>}
                    />
                    {this.getOptions()}
                  </select>
                }
              />
            </Card>
            <Card title={<Translation k={"NOTES"} />} span={12}>
              <Textarea
                small
                onChange={(event) =>
                  this.props.updateReport(
                    report,
                    "comments",
                    event.target.value
                  )
                }
                value={report.comments || ""}
              />
            </Card>
            <ActionsBlock>
              <Button
                disabled={
                  !(
                    this.isDirty(report) &&
                    this.isValid(report) &&
                    !this.props.saving
                  )
                }
                label={
                  (!report.temp &&
                    this.isDirty(report) &&
                    this.isValid(report)) ||
                  report.temp ? (
                    <Translation k={"SAVE"} />
                  ) : (
                    <Translation k={"SAVED"} />
                  )
                }
                callback={() => this.handlePostReport(report, id)}
              />
              {id ? (
                <Button
                  label={<Translation k={"DELETE"} />}
                  callback={() => this.handleDelete(report)}
                />
              ) : (
                ""
              )}
              {key === reports.length - 1 ? (
                <Button
                  label={<Translation k={"ADD_NEW"} />}
                  callback={() =>
                    this.props.addReport(
                      moment(this.props.selectedDate).format("YYYY-MM-DD")
                    )
                  }
                />
              ) : (
                ""
              )}
            </ActionsBlock>
          </React.Fragment>
        );
      });

      return (
        <div className={this.props.className}>
          <PageTitle>
            <Translation k={"EDIT_WORK_REPORT"} />
          </PageTitle>
          <CardGrid>
            <Menu>{this.getMenu()}</Menu>
            {reportsToRender}
            {!reports.length ? (
              <ActionsBlock>
                <Button
                  label={<Translation k={"ADD_NEW"} />}
                  callback={() =>
                    this.props.addReport(
                      moment(this.props.selectedDate).format("YYYY-MM-DD")
                    )
                  }
                />
              </ActionsBlock>
            ) : (
              ""
            )}
          </CardGrid>

          <TimePicker
            value={getTime(this.state.report, this.state.label)}
            style={{ display: "none" }}
            ampm={false}
            ref={(node) => {
              this["picker"] = node;
            }}
            onChange={(e) => {
              this.handleTimeChange(e, this.state.report, this.state.label);
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const selectedDay = state.getIn(["reports", "selected_day"]);
  const selectedDate = moment(new Date()).add(selectedDay, "day");
  const reports = state.getIn(["reports", "reports"]);
  const filterReports = reports.size
    ? reports.filter((report) =>
        moment(report.get("start")).isSameOrAfter(
          moment(new Date()).add(-3, "day")
        )
      )
    : [];
  return {
    selectedDay: selectedDay,
    selectedDate: selectedDate,
    places: state.get("places"),
    reports: filterReports,
    saving: state.getIn(["reports", "saving"]),
    reportsLoading: state.getIn(["state", "reportsLoading"]),
    sitesLoading: state.getIn(["state", "sitesLoading"]),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeDay: Actions.changeSelectedReportDay,
      loadReports: Actions.requestReports,
      postReport: Actions.postReport,
      updateReport: Actions.updateReport,
      saveReport: Actions.saveReport,
      deleteReport: Actions.deleteReport,
      softDeleteReport: Actions.softDeleteReport,
      addReport: Actions.addReport,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(styled(Profile)``));
