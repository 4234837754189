import axios from "axios";

const baseURL = "https://api.baronajumti.lv/api/v1/";
// const baseURL = "http://apidev.baronajumti.lv/api/v1/";

export function init() {
  return {
    type: "ON_INIT",
    payload: "",
  };
}

export function changeSelectedStatistics(label) {
  return {
    type: "CHANGE_SELECTED_STATISTICS",
    payload: label,
  };
}

export function changeSelectedReportDay(label) {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_SELECTED_REPORT_DAY",
      payload: label,
    });
    dispatch({
      type: "RESET_DRAFT",
      payload: "",
    });
    dispatch({
      type: "SELECT_DRAFT",
      payload: "",
    });
  };
}

export function changeCalendarMonth(index) {
  return {
    type: "CHANGE_CALENDAR_MONTH",
    payload: index,
  };
}

export function changeProfileBirthDate(date) {
  return {
    type: "CHANGE_PROFILE_BIRTH_DATE",
    payload: date,
  };
}

export function changeProfileEmail(value) {
  return {
    type: "CHANGE_PROFILE_EMAIL",
    payload: value,
  };
}

export function receiveToken(token) {
  return {
    type: "RECEIVE_TOKEN",
    payload: token,
  };
}

export function resetDraft() {
  return {
    type: "RESET_DRAFT",
    payload: "",
  };
}

export function updateDraft(label, value) {
  return {
    type: "UPDATE_DRAFT",
    payload: { label: label, value: value },
  };
}

export function updateReport(id, label, value) {
  return {
    type: "UPDATE_REPORT",
    payload: { id: id, label: label, value: value },
  };
}

export function addReport(date) {
  return {
    type: "ADD_REPORT",
    payload: { date: date },
  };
}

export function requestPlaces() {
  return {
    types: ["PLACES_LOAD_PENDING", "PLACES_LOAD_SUCCESS", "PLACES_LOAD_ERROR"],
    url: "/places",
    baseURL,
  };
}

export function requestReports() {
  return {
    types: [
      "REPORTS_LOAD_PENDING",
      "REPORTS_LOAD_SUCCESS",
      "REPORTS_LOAD_ERROR",
    ],
    url: "/reports",
    baseURL,
    toDispatch: [
      {
        type: "SELECT_DRAFT",
        payload: "",
      },
    ],
  };
}

export function requestStatistics() {
  return {
    types: [
      "STATISTICS_LOAD_PENDING",
      "STATISTICS_LOAD_SUCCESS",
      "STATISTICS_LOAD_ERROR",
    ],
    url: "/statistics",
    baseURL,
  };
}

export function requestUser() {
  return {
    types: ["USER_LOAD_PENDING", "USER_LOAD_SUCCESS", "USER_LOAD_ERROR"],
    url: "/user",
    baseURL,
  };
}

export function postReport(draft) {
  return {
    types: ["REPORT_POST_PENDING", "REPORT_POST_SUCCESS", "REPORT_POST_ERROR"],
    url: "/reports",
    method: "post",
    data: draft,
    baseURL,
  };
}

export function saveReport(id, report) {
  let { original, ...draft } = report;
  return {
    types: ["REPORT_SAVE_PENDING", "REPORT_SAVE_SUCCESS", "REPORT_SAVE_ERROR"],
    url: "/reports/" + id,
    method: "post",
    data: draft,
    baseURL,
  };
}

export function deleteReport(id) {
  return {
    types: [
      "REPORT_DELETE_PENDING",
      "REPORT_DELETE_SUCCESS",
      "REPORT_DELETE_ERROR",
    ],
    url: "/reports/" + id,
    method: "delete",
    baseURL,
  };
}

export function softDeleteReport(id) {
  return {
    type: "REPORT_SOFT_DELETE",
    payload: id,
  };
}

//profile

export function changeProfileField(id, field) {
  return {
    types: [
      "PROFILE_FIELD_POST_PENDING",
      "PROFILE_FIELD_POST_SUCCESS",
      "PROFILE_FIELD_POST_ERROR",
    ],
    url: "/user/" + id,
    method: "post",
    data: field,
    baseURL,
  };
}

//login

export function requestLogin(email, password) {
  const loginParams = {
    email: email,
    password: password,
    remember_me: 1,
  };
  return (dispatch) => {
    dispatch({ type: "LOGIN_PENDING" });
    axios
      .post(baseURL + "login", loginParams)
      .then((r) => {
        dispatch(receiveToken(r.data.access_token));
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: "LOGIN_ERROR" });
      });
  };
}

export function logout() {
  return {
    type: "LOGOUT",
    payload: "",
  };
}

export function updateLoginEmail(value) {
  return {
    type: "UPDATE_LOGIN_EMAIL",
    payload: value,
  };
}

export function handlePasswordChange(value) {
  return {
    type: "UPDATE_PASSWORD_EMAIL",
    payload: value,
  };
}

export function handleRememberMe(value) {
  return {
    type: "UPDATE_REMEMBER_ME",
    payload: value,
  };
}

export function setMenuState(value) {
  return {
    type: "SET_MENU_STATE",
    payload: value,
  };
}

//translations

export function requestDictionary() {
  return {
    types: [
      "DICTIONARY_LOAD_PENDING",
      "DICTIONARY_LOAD_SUCCESS",
      "DICTIONARY_LOAD_ERROR",
    ],
    url: "/translations",
    baseURL,
  };
}

export function changeLanguage(language) {
  return {
    type: "CHANGE_LANGUAGE",
    payload: language,
  };
}

//passwords

export function changePasswordValue(payload) {
  return {
    type: "CHANGE_PASSWORD_VALUE",
    payload: payload,
  };
}

export function clearPasswords() {
  return {
    type: "CLEAR_PASSWORDS",
    payload: "",
  };
}

export function updatePassword(payload) {
  return {
    types: [
      "UPDATE_PASSWORD_PENDING",
      "UPDATE_PASSWORD_SUCCESS",
      "UPDATE_PASSWORD_ERROR",
    ],
    url: "/user/change-password",
    method: "post",
    data: payload,
    baseURL,
  };
}

export function viewPasswordChange() {
  return {
    type: "VIEW_PASSWORD_CHANGE",
    payload: "",
  };
}

//stock

export function requestStock() {
  return {
    types: ["STOCK_LOAD_PENDING", "STOCK_LOAD_SUCCESS", "STOCK_LOAD_ERROR"],
    url: "/inventory",
    baseURL /*: 'http://my-json-server.typicode.com/nickLeidman/fake-api'*/,
  };
}

export function sentStockMessage(payload) {
  return {
    types: [
      "STOCK_MESSAGE_PENDING",
      "STOCK_MESSAGE_SUCCESS",
      "STOCK_MESSAGE_ERROR",
    ],
    url: "/inventory/send/text",
    baseURL,
    data: payload,
    method: "post",
  };
}

export function sentStockOrder(payload) {
  return {
    types: ["STOCK_ORDER_PENDING", "STOCK_ORDER_SUCCESS", "STOCK_ORDER_ERROR"],
    url: "/inventory/send/inventory",
    baseURL,
    data: payload,
    method: "post",
  };
}

export function toggleItemInStock(id) {
  return {
    type: "TOGGLE_ITEM_IN_STOCK",
    payload: id,
  };
}

export function resetStockStatus() {
  return {
    type: "RESET_STOCK_STATUS",
    payload: "",
  };
}

export function clearSelectedInStock() {
  return {
    type: "CLEAR_SELECTED_IN_STOCK",
    payload: "",
  };
}

export function stockMessageChange(messege) {
  return {
    type: "STOCK_MESSAGE_CHANGE",
    payload: messege,
  };
}

//image modal

export function setImageModal(payload) {
  return {
    type: "SET_MODAL_IMAGE",
    payload,
  };
}
