import React, {Component} from 'react';

import styled from 'styled-components'
import Button from "./Button";
import Translation from "../dictionary/Translation";
import Input from "./Input";
import InputLabel from "./InputLabel";


const Container = styled.div`
  display: flex;
  padding-top: 7px;
  
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    flex-wrap: wrap;
    input{
      margin-bottom: 10px;
    }
  }
`;

class InputBlock extends Component {
    constructor(props) {
        super(props);

        this.handleCalback = this.handleCalback.bind(this);

        this.input = React.createRef();
    }

    handleCalback(){
        this.props.callback(this.input.current.value);
    }

    render() {
        return (
            <div className={this.props.className}>
                <InputLabel>
                    {this.props.label}
                    <Container>
                        <Input
                            innerRef={this.input} defaultValue={this.props.value}/>
                        <Button label={<Translation k={'SAVE'}/>} callback={this.handleCalback}/>
                    </Container>
                </InputLabel>
            </div>
        )
    }
}

export default styled(InputBlock)`
`;