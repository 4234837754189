import React, {Component} from 'react';

import styled from 'styled-components'
import moment from 'moment'

import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as Actions from '../actions/actions';
import PageTitle from "../common/PageTitle";
import CardGrid from "../common/CardGrid";
import Menu from "../common/Menu";
import Button from "../common/Button";
import Card from "../common/Card";
import Calendar from "../calendar/Calendar";
import Legend from "../calendar/Legend";
import Translation from "../dictionary/Translation";
import Loader from "../common/Loader";

class Home extends Component {
    constructor(props) {
        super(props);

        this.getMonthMenu = this.getMonthMenu.bind(this)
    }

    handleClick(index) {
        this.props.changeCalendarMonth(index)
    }

    componentWillMount() {
        this.props.loadReports();
    }

    getMonthMenu() {
        let month = moment(new Date()).add(-2, 'month').locale(this.props.selectedLanguage);
        const buttons = [];
        for (let i = -2; i <= 0; i++) {
            const callback = () => {
                this.handleClick(i);
            };
            const label = month.format('MMMM');
            const isActive = this.props.selectedCalendarMonth === i;
            buttons.push(
                <Button callback={callback} capitalize label={label} secondary={true} key={i} active={isActive}/>
            );
            month.add(1, 'month');
        }
        return buttons;
    }

    render() {
        if (this.props.reportsLoading) {
            return (
                <Loader/>
            );
        }
        else {
            return (
                <div className={this.props.className}>
                    <PageTitle><Translation k={'WORK_ACCOUNTING_CALENDAR'}/></PageTitle>
                    <CardGrid>
                        <Menu>
                            {this.getMonthMenu()}
                        </Menu>
                        <Card span={8}>
                            <Calendar month={moment(new Date()).add(this.props.selectedCalendarMonth, "month")}
                                      reports={this.props.reports} language={this.props.selectedLanguage}/>
                        </Card>
                        <Card span={4} title={<Translation k={'NOTATION'}/>}>
                            <Legend/>
                        </Card>
                    </CardGrid>
                </div>
            )
        }
    }
}


function mapStateToProps(state) {
    return {
        selectedCalendarMonth: state.getIn(['state', 'selectedCalendarMonth']),
        reports: state.getIn(['reports', 'reports']),
        reportsLoading: state.getIn(['state', 'reportsLoading']),
        selectedLanguage: state.getIn(['state', 'selectedLanguage']),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeCalendarMonth: Actions.changeCalendarMonth,
        loadReports: Actions.requestReports,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(Home)`
`));