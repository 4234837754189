import React, {Component} from 'react';

import styled from 'styled-components'
import moment from 'moment';
import Card from "../common/Card";
import ProfileImagePicker from "../profile/ImagePicker";
import PageTitle from "../common/PageTitle";
import CardGrid from "../common/CardGrid";

import DatePicker from 'material-ui-pickers/DatePicker';
import Button from "../common/Button";

import StatisticsBlock from "../common/StatisticsBlock";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as Actions from "../actions/actions";
import InputBlock from "../common/InputBlock";
import Translation from "../dictionary/Translation";
import Loader from "../common/Loader";
import LangMenu from "../common/LangMenu";
import {Link} from "react-router-dom";


const BirthDateBlock = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    flex-wrap: wrap;
  }
`;

class Profile extends Component {
    constructor(props) {
        super(props);

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    openPicker = () => {
        this.picker.open();
    };

    componentWillMount() {
        this.props.requestUser();
    }

    handleDateChange(e) {
        this.handleFieldChange('birth_date', e.format('YYYY-MM-DD HH:mm'));
    }

    handleFieldChange(label, value) {
        let obj = {};
        obj[label] = value;
        this.props.changeField(this.props.profile.id, obj);
    }

    handleEmailChange(value) {
        this.props.changeEmail(value);
    }

    render() {
        if (this.props.profileLoading) {
            return (
                <Loader/>
            );
        }
        else {
            return (
                <div className={this.props.className}>
                    <PageTitle><Translation k={'MY_PROFILE'}/></PageTitle>
                    <CardGrid>
                        <Card title={<Translation k={'PROFILE_IMAGE'}/>} span={6}>
                            <ProfileImagePicker image={this.props.profile.image}/>
                        </Card>
                        <Card title={<Translation k={'BIRTH_DATE'}/>} span={6}>
                            <BirthDateBlock>
                                <StatisticsBlock label={<Translation k={'CHANGE_BIRTH_DATE'}/>}
                                                 value={moment(this.props.profile.birth_date).format('DD.MM.YYYY')}/>
                                <Button label={<Translation k={'CHANGE'}/>} callback={this.openPicker}/>
                                <DatePicker style={{display: 'none'}}
                                            ref={(node) => {
                                                this.picker = node;
                                            }}
                                            value={moment(this.props.profile.birth_date)}
                                            onChange={this.handleDateChange}
                                />
                            </BirthDateBlock>
                        </Card>
                        <Card title={<Translation k={'YOUR_FIRST_NAME'}/>} value={this.props.profile.first_name}
                              span={6}>
                            <InputBlock label={<Translation k={'CHANGE_FIRST_NAME'}/>}
                                        value={this.props.profile.first_name}
                                        callback={(value) => this.handleFieldChange('first_name', value)}/>
                        </Card>
                        <Card title={<Translation k={'YOUR_LAST_NAME'}/>} value={this.props.profile.last_name} span={6}>
                            <InputBlock label={<Translation k={'CHANGE_LAST_NAME'}/>}
                                        value={this.props.profile.last_name}
                                        callback={(value) => this.handleFieldChange('last_name', value)}/>
                        </Card>
                        <Card title={<Translation k={'LANGUAGE'}/>} span={6}>
                            <LangMenu/>
                        </Card>
                        <Card title={<Translation k={'CHANGE_PASSWORD'}/>} span={6}>
                            <Link to={'/change-password'}>
                                <Button tag={'div'} label={<Translation k={'CHANGE'}/>}/>
                            </Link>
                        </Card>
                    </CardGrid>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        profile: state.get('profile'),
        profileLoading: state.getIn(['state', 'profileLoading']),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeDate: Actions.changeProfileBirthDate,
        changeField: Actions.changeProfileField,
        changeEmail: Actions.changeProfileEmail,
        requestUser: Actions.requestUser,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(Profile)`

`));