import React, {Component} from 'react';

import styled from 'styled-components'
import H from '../typography/H'
import {mixins} from "../../mixins";

const Header = styled.div`
  padding-bottom: ${p => p.theme.sCardPadding * 0.8}px;
  margin-bottom: ${p => p.theme.sCardPadding * 0.8}px;
  border-bottom: 2px solid ${p => p.theme.cBg};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const Impact = styled.span`
  color: ${p => p.theme.cTheme};
`;

const CloseButton = styled.button`
  height: 32px;
  width: 32px;
  border: none;
  background: none;
  outline: none !important;
  cursor: pointer;
  position: relative;
  border-radius: ${p => p.theme.sRadius};
  ${mixins.ease()};
  &:before, &:after{
    ${mixins.pseudoToBlock()};
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 6px;
    
    width: 20px;
    height: 2px;
    border-radius: 1px;
    background-color: ${p => p.theme.cSecondaryText};
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
  &:hover, &:focus{
    background-color: ${p => p.theme.cBg};
  }
`;

class Card extends Component {
    constructor(props) {
        super(props);

        this.getTitle = this.getTitle.bind(this);
    }

    getTitle() {
        const value = typeof (this.props.value) !== "undefined" ?
            <span>{': '}<Impact>{this.props.value}</Impact></span> :
            '';
        if (typeof (this.props.title) !== "undefined") {
            return <Header>
                <div>
                    <H size={2}>{this.props.title}{value}</H>
                </div>
                <div>
                    {typeof (this.props.close) !== "undefined" && this.props.close ?
                        <CloseButton onClick={this.props.close}/> : ''}
                </div>
            </Header>;
        } else {
            return '';
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                {this.getTitle()}
                {this.props.children}
            </div>
        )
    }
}

export default styled(Card)`
  padding: ${p => p.theme.sCardPadding}px;
  border-radius: ${p => p.theme.sRadius}px;
  background-color: ${p => p.theme.cWhite};
  grid-column: span ${p => p.span ? p.span : 1};
  min-width: 0;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    grid-column: span 1;
  }
`;