import React, {Component} from 'react';

import styled from 'styled-components'
import PageTitle from "../common/PageTitle";
import CardGrid from "../common/CardGrid";
import Menu from "../common/Menu";
import Button from "../common/Button";
import Card from "../common/Card";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as Actions from '../actions/actions';
import StatisticsBlock from "../common/StatisticsBlock";
import Translation from "../dictionary/Translation";
import Loader from "../common/Loader";
import moment from "moment";
import SiteCard from "../common/SiteCard";

const StatisticsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 10px 0;
  >*{
    display: flex;
    @media (max-width: ${p => p.theme.sBreakpoint}px){
      display: block;
    }
  }
`;

class Home extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getLabel = this.getLabel.bind(this);
        this.getSite = this.getSite.bind(this);
    }

    handleClick(label) {
        this.props.changeSelectedStatistics(label);
    }

    componentWillMount() {
        this.props.requestStatistics();
    }

    getLabel(){
        const now = moment(new Date()).locale(this.props.selectedLanguage);
        switch (this.props.selectedStatistics) {
            case 'month':{
                return '('+now.format('MMMM')+')'
            }
            case 'week':{
                return '('+now.format('WW')+')'
            }
            case 'year':{
                return '('+now.format('YYYY')+')'
            }
            default : return '';
        }
    }

    getContent() {
        const statistics = this.props.statistics[this.props.selectedStatistics];
        return (
            <StatisticsRow>
                <div>
                    <StatisticsBlock label={<Translation k={'HOURS'}/>}
                                     value={parseInt(statistics.hours, 10) ? parseInt(statistics.hours, 10) : 0}/>
                    {/*<StatisticsBlock label={<Translation k={'OVERTIME'}/>}
                                     value={parseInt(statistics.overtime, 10) ? parseInt(statistics.overtime, 10) : 0}/>*/}
                    <StatisticsBlock label={<Translation k={'DAYS'}/>}
                                     value={parseInt(statistics.days, 10) ? parseInt(statistics.days, 10) : 0}/>
                </div>
                {/*<div>
                    <StatisticsBlock reversed={true} label={<Translation k={'TOTAL'}/>}
                                     value={parseInt(statistics.hours, 10) ? parseInt(statistics.hours, 10) : 0}/>
                </div>*/}
            </StatisticsRow>
        );
    }

    getSite(site_id) {
        if (site_id) {
            const site = this.props.sites.find((site) => site.id.toString() === site_id.toString());
            if(site){
                return this.props.sites.find((site) => site.id.toString() === site_id.toString());
            }
            console.warn('Statistics has no site to render! Probably most popular site has been deleted');
            return false;
        }
        console.warn('Statistics has no site id!');
        return false
    }

    render() {
        if (!(!this.props.statisticsLoading && !this.props.sitesLoading)) {
            return (
                <Loader/>
            );
        }
        else {
            const statistics = this.props.statistics[this.props.selectedStatistics];
            return (
                <div className={this.props.className}>
                    <PageTitle><Translation k={'SUMMARY'}/></PageTitle>
                    <CardGrid>
                        <Menu>
                            <Button callback={() => this.handleClick('month')} label={<Translation k={'MONTH'}/>}
                                    secondary={true}
                                    active={this.props.selectedStatistics === 'month'}/>
                            <Button callback={() => this.handleClick('week')} label={<Translation k={'WEEK'}/>}
                                    secondary={true}
                                    active={this.props.selectedStatistics === 'week'}/>
                            <Button callback={() => this.handleClick('year')} label={<Translation k={'YEAR'}/>}
                                    secondary={true}
                                    active={this.props.selectedStatistics === 'year'}/>
                        </Menu>
                        <Card title={<span><Translation k={'WORKED_OUT'}/><span>{' '+ this.getLabel()}</span></span>} span={12}>
                            {this.getContent()}
                        </Card>
                        {statistics.place_id ? <Card span={12}>
                            <SiteCard place={this.getSite(statistics.place_id)}/>
                        </Card> : ''}
                    </CardGrid>
                </div>
            )
        }
    }
}


function mapStateToProps(state) {
    return {
        statistics: state.get('statistics'),
        sites: state.get('places'),
        selectedStatistics: state.getIn(['state', 'selectedStatistics']),
        statisticsLoading: state.getIn(['state', 'statisticsLoading']),
        sitesLoading: state.getIn(['state', 'sitesLoading']),
        selectedLanguage: state.getIn(['state', 'selectedLanguage']),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeSelectedStatistics: Actions.changeSelectedStatistics,
        requestStatistics: Actions.requestStatistics,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(Home)`
`));