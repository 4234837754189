import { fromJS } from "immutable";

const defState = fromJS({
  selectedStatistics: "month",
  selectedCalendarMonth: 0,
  loading: { dictionary: true },
  access_token: "",
  statisticsLoading: true,
  reportsLoading: true,
  profileLoading: true,
  sitesLoading: true,
  passwordChangeViewed: false,
  defaultLanguage: "lv",
  selectedLanguage: localStorage.getItem("selectedLanguage") || "lv",
  languages: ["lv", "ru", "sv"],
  stockLoading: true,
  rememberMe: false,

  loginEmail: "",
  loginPassword: "",

  failedLogin: false,
  menuOpen: false,
});

export default function reducer(state = defState, action) {
  switch (action.type) {
    case "STATISTICS_LOAD_PENDING": {
      return state.set("statisticsLoading", true);
    }
    case "STATISTICS_LOAD_SUCCESS": {
      return state.set("statisticsLoading", false);
    }
    case "REPORTS_LOAD_PENDING": {
      return state.set("reportsLoading", true);
    }
    case "REPORTS_LOAD_SUCCESS": {
      return state.set("reportsLoading", false);
    }
    case "USER_LOAD_PENDING": {
      return state.set("profileLoading", true);
    }
    case "USER_LOAD_SUCCESS": {
      return state.set("profileLoading", false);
    }

    case "START_LOADING": {
      return state.setIn(["loading", action.payload], true);
    }
    case "STOP_LOADING": {
      return state.setIn(["loading", action.payload], false);
    }

    case "DICTIONARY_LOAD_SUCCESS": {
      return state
        .setIn(["loading", "dictionary"], false)
        .setIn(["languages"], action.payload.get("languages"));
    }

    case "CHANGE_LANGUAGE": {
      localStorage.setItem("selectedLanguage", action.payload);
      return state.setIn(["selectedLanguage"], action.payload);
    }

    case "RECEIVE_TOKEN": {
      return state
        .set("access_token", action.payload)
        .set("loginEmail", "")
        .set("loginPassword", "")
        .set("loginFailed", false);
    }
    case "LOGIN_PENDING": {
      return state.set("loginFailed", false);
    }
    case "LOGIN_ERROR": {
      return state.set("loginFailed", true);
    }
    case "CHANGE_SELECTED_STATISTICS": {
      return state.set("selectedStatistics", action.payload);
    }
    case "CHANGE_CALENDAR_MONTH": {
      return state.set("selectedCalendarMonth", action.payload);
    }
    case "LOGOUT": {
      localStorage.removeItem("TOKEN");
      return state.set("access_token", "");
    }
    case "UPDATE_LOGIN_EMAIL": {
      return state.set("loginEmail", action.payload);
    }
    case "UPDATE_PASSWORD_EMAIL": {
      return state.set("loginPassword", action.payload);
    }
    case "UPDATE_REMEMBER_ME": {
      return state.set("rememberMe", action.payload);
    }
    case "SET_MENU_STATE": {
      return state.set("menuOpen", action.payload);
    }

    case "PLACES_LOAD_PENDING": {
      return state.set("sitesLoading", true);
    }
    case "PLACES_LOAD_SUCCESS": {
      return state.set("sitesLoading", false);
    }
    case "VIEW_PASSWORD_CHANGE": {
      return state.set("passwordChangeViewed", true);
    }
    case "STOCK_LOAD_PENDING": {
      return state.set("stockLoading", true);
    }
    case "STOCK_LOAD_SUCCESS": {
      return state.set("stockLoading", false);
    }
    default:
      return state;
  }
}
