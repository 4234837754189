import React, {Component} from 'react';

import styled from 'styled-components'
import PageTitle from "../common/PageTitle";

import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import Translation from "../dictionary/Translation";
import Loader from "../common/Loader";
import InputLabel from "../common/InputLabel";
import Input from "../common/Input";
import Button from "../common/Button";
import * as Actions from '../actions/actions';
import {rgba} from "polished";

const Form = styled.form`
  max-width: 600px;
  margin: auto;
  padding: ${p => p.theme.sCardPadding}px;
  border-radius: ${p => p.theme.sRadius}px;
  background-color: ${p => p.theme.cWhite};
`;

const InputBlock = styled.div`
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
`;

const WarningMessage = styled.div`

`;

const MessageCard = styled.div`
  padding: ${p => p.theme.sCardPadding}px;
  border-radius: ${p => p.theme.sRadius}px;
  background-color: ${p => p.error ? rgba(p.theme.cWeekend, 0.4) : rgba(p.theme.cTheme, 0.4)};
`;

class PasswordChange extends Component {

    handleSubmit = (e) => {
        e.preventDefault();
        if((this.props.passwords.matching && this.props.passwords.valid)){
            this.props.updatePassword({
                'old_password': this.props.passwords.currentPassword,
                'password': this.props.passwords.newPassword,
                'password_confirmation': this.props.passwords.newPasswordConfirm,
            });
        }
    };

    componentWillMount() {
        this.props.clearPasswords();
        this.props.viewPasswordChange();
    }

    componentWillUnmount() {
        this.props.clearPasswords();
    }

    render() {
        if (this.props.profileLoading) {
            return (
                <Loader/>
            );
        } else {
            const buttonDisabled = !(this.props.passwords.matching && this.props.passwords.valid);
            return (
                <div className={this.props.className}>
                    <PageTitle><Translation k={'CHANGE_PASSWORD'}/></PageTitle>
                    <Form onSubmit={(e) => this.handleSubmit(e)}>
                        {this.props.passwordChangeRequired ? <InputBlock>
                            <WarningMessage><Translation k={'PLEASE_CHANGE_YOUR_PASSWORD'}/></WarningMessage>
                        </InputBlock> : ''}
                        {this.props.passwords.pending ? <InputBlock>
                            <Loader/>
                        </InputBlock> : ''}
                        {this.props.passwords.error ? <InputBlock>
                            <MessageCard error><Translation k={'ERROR_WHILE_CHANGING_PASSWORD'}/></MessageCard>
                        </InputBlock> : ''}
                        {this.props.passwords.success ? <InputBlock>
                            <MessageCard ><Translation k={'PASSWORD_SUCCESSFULLY_CHANGED'}/></MessageCard>
                        </InputBlock> : ''}
                        {this.props.passwords.unauthorized ? <InputBlock>
                            <MessageCard error><Translation k={'CURRENT_PASSWORD_IS_INCORRECT'}/></MessageCard>
                        </InputBlock> : ''}
                        <InputBlock>
                            <InputLabel>
                                <Translation k={'CURRENT_PASSWORD'}/>
                                <Input autoComplete="current-password" type="password"
                                       value={this.props.passwords.currentPassword} onChange={(e) => {
                                    this.props.changePasswordValue({key: 'currentPassword', value: e.target.value})
                                }}/>
                            </InputLabel>
                        </InputBlock>
                        <InputBlock>
                            <InputLabel>
                                <Translation k={'NEW_PASSWORD'}/>
                                <Input autoComplete="new-password" type="password"
                                       value={this.props.passwords.newPassword} onChange={(e) => {
                                    this.props.changePasswordValue({key: 'newPassword', value: e.target.value})
                                }}/>
                            </InputLabel>
                        </InputBlock>
                        <InputBlock>
                            <InputLabel>
                                <Translation k={'NEW_PASSWORD_CONFIRM'}/>
                                <Input autoComplete="new-password" type="password"
                                       value={this.props.passwords.newPasswordConfirm} onChange={(e) => {
                                    this.props.changePasswordValue({key: 'newPasswordConfirm', value: e.target.value})
                                }}/>
                            </InputLabel>
                        </InputBlock>
                        <InputBlock>
                            <Button disabled={buttonDisabled} type={'submit'} label={<Translation k={'SUBMIT'}/>}/>
                        </InputBlock>
                        {!this.props.passwords.matching ? <InputBlock>
                            <WarningMessage><Translation k={'NEW_PASSWORDS_MISMATCH'}/></WarningMessage>
                        </InputBlock> : ''}
                    </Form>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        passwords: state.get('password'),
        profile: state.get('profile'),
        profileLoading: state.getIn(['state', 'profileLoading']),
        passwordChangeViewed: state.getIn(['state', 'passwordChangeViewed']),
        passwordChangeRequired: state.getIn(['profile', 'password_change']),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changePasswordValue: Actions.changePasswordValue,
        clearPasswords: Actions.clearPasswords,
        updatePassword: Actions.updatePassword,
        viewPasswordChange: Actions.viewPasswordChange,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(PasswordChange)`

`));