import React, {Component} from 'react';

import styled from 'styled-components'
import H from "../typography/H";

class PageTitle extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        return (
            <div className={this.props.className}>
                <H size={1}>{this.props.children}</H>
            </div>
        )
    }
}

export default styled(PageTitle)`
  margin-bottom: 20px;
`;