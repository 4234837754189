import React, {Component} from 'react';

import styled from 'styled-components'

import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import Button from "../common/Button";
import * as Actions from '../actions/actions';
import {Redirect} from "react-router-dom";
import Translation from "../dictionary/Translation";
import image from '../../img/roof.jpg'
import logo from '../../img/logo-square.svg'
import LangMenu from "../common/LangMenu";
import TranslationProvider from "../dictionary/TranslationProvider";
import {mixins} from "../../mixins";

const Body = styled.div`
  min-height: 100%;
  width: 100%;
  position: relative;
  padding: 40px 20px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  //@media (min-width: 1024px){
  //  min-height: 0;
  //  max-width: 480px;
  //  padding-top: 20px;
  //  padding-bottom: 20px;
  //  align-items: flex-end;
  //  justify-content: flex-end;
  //}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  //@media (min-width: 1024px){
  //  margin-right: 0;
  //  background: #161516;
  //  padding: 30px 20px;
  //  border-radius: 20px;
  //  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.3);
  //  margin-top: 100px;
  //}
`;

const Background = styled.div`
  position: absolute;
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
  &:after{
    ${mixins.pseudoToBlock()};
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.93;
    background: #161516;
  }
  //@media (min-width: 1024px){
  //  top: 20px;
  //  bottom: 100px;
  //  right: 120px;
  //  border-radius: 20px;
  //  overflow: hidden;
  //}
`;

const Content = styled.div`
  color: ${p => p.theme.cWhite};
  max-width: 320px;
  margin: auto;
`;

const LangContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
`;

const Logo = styled.div`
  margin: auto;
  text-align: center;
  margin-bottom: 15px;
`;

const Header = styled.h1`
  margin-bottom: 30px;
  font-size: 30px;
  margin-top: 0;
  text-align: center;
  line-height: 1.3;
`;

const Form = styled.form`
  
`;

const Input = styled.input`
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid transparent;
  width: 100%;
  box-sizing: border-box;
  padding: 18px 32px;
  outline: none !important;
  ${mixins.ease()};
  color: ${p => p.theme.cWhite};
  margin-bottom: 16px;
  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  ::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  :-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  
  &:focus{
    border: 2px solid ${p => p.theme.cWhite};
  }
`;

const CheckboxItem = styled.div`
  margin-bottom: 16px;
  padding: 8px 0;
`;

const Checkbox = styled.input`
  height: 1px;
  width: 1px;
  position: absolute;
  left: -999999px;
  opacity: 0;
  
  &:checked + label:after{
    opacity: 1;
    transform: none;
  }
  
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  &:before, &:after{
    ${mixins.pseudoToBlock()};
    
  }
  &:after{
    position: absolute;
    height: 40px;
    width: 40px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.629' height='10.446'%3E%3Cg data-name='25404'%3E%3Cpath data-name='Path 62' d='M13.383 1.442l-1.2-1.2a.849.849 0 00-1.2 0l-5.76 5.78-2.585-2.593a.814.814 0 00-.6-.246.814.814 0 00-.6.246l-1.2 1.2a.849.849 0 000 1.2l3.183 3.183 1.2 1.2a.849.849 0 001.2 0l1.2-1.2 6.366-6.366a.849.849 0 000-1.2z' fill='%23f0f0f0'/%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    transform: scale(0.8);
    ${mixins.ease()}
  }
  &:before{
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 20px;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 40px;
`;

const Copyright = styled.div`
  font-size: ${p => p.theme.sFontSmall};
  margin-top: 35px;
  text-align: center;
  color: ${p => p.theme.cWhite};
  opacity: 0.4;
`;


class Login extends Component {

    handleLogin(e) {
        e.preventDefault();
        this.props.login(this.props.loginEmail, this.props.loginPassword);
    }

    render() {
        const {
            token,
            className,
            loginEmail,
            loginPassword,
            rememberMe,
            handleRememberMe,
            updateLoginEmail,
            handlePasswordChange
        } = this.props;

        if (!token) {
            return (
                <div className={className}>
                    <Body>
                        <Background/>
                        <Card>
                            <LangContainer>
                                <LangMenu transparent noMargin/>
                            </LangContainer>
                            <Content>
                                <Logo>
                                    <TranslationProvider
                                        k={'STAFF_REPORTING_SYSTEM'}
                                        render={text => <img src={logo} alt={text}/>}/>
                                </Logo>
                                <Header><Translation k={'STAFF_REPORTING_SYSTEM'}/></Header>
                                {/*<Header>Personalrapporter&shy;ingssystem</Header>*/}
                                <Form onSubmit={(e) => this.handleLogin(e)}>
                                    <TranslationProvider k={'EMAIL'} render={text =>
                                        <Input id={'email'} onChange={(e) => updateLoginEmail(e.target.value)}
                                               value={loginEmail}
                                               type='email' name='email' placeholder={text} required/>
                                    }
                                    />
                                    <TranslationProvider k={'PASSWORD'} render={text =>
                                        <Input id={'password'} onChange={(e) => handlePasswordChange(e.target.value)}
                                               value={loginPassword}
                                               type='password' name='password' placeholder={text} required/>
                                    }
                                    />
                                    <CheckboxItem>
                                        <Checkbox
                                            id="rememberMe"
                                            type="checkbox"
                                            name="remember-me"
                                            checked={rememberMe}
                                            onChange={(e) => handleRememberMe(e.target.checked)}
                                        />
                                        <CheckboxLabel htmlFor="rememberMe"><Translation
                                            k={'REMEMBER_ME'}/></CheckboxLabel>
                                    </CheckboxItem>
                                    {this.props.loginFailed ? <ErrorMessage><Translation
                                        k={'EMAIL_OR_PASSWORD_IS_INCORRECT'}/></ErrorMessage> : ''}
                                    <Button fullWidth huge label={<Translation k={'LOGIN'}/>}/>
                                </Form>
                            </Content>
                            <Copyright>
                                <Translation k={'COPYRIGHT_TEXT'}/>
                            </Copyright>
                        </Card>
                    </Body>
                </div>
                /*<div className={this.props.className}>
                    <LoginContainer>
                        <Image/>
                        <LoginColumn>
                            <MenuContainer>
                                <LangMenu dark/>
                            </MenuContainer>
                            <LoginCard onKeyDown={(e) => e.which === 13 ? this.handleLogin() : ''}>
                                <Logo src={logo} alt={'Barona Jumti'}/>
                                <Header><Translation k={'STAFF_REPORTING_SYSTEM'}/></Header>
                                <LoginForm>
                                    <TranslationProvider k={'EMAIL'} render={translation =>
                                        <Input>
                                            <InputIcon><Icon name="profile"/></InputIcon>
                                            <label htmlFor={'email'}>{translation}</label>
                                            <input id={'email'} onChange={(e) => this.handleEmailChange(e)}
                                                   value={this.props.loginEmail}
                                                   type='email' name='email' placeholder={translation}/>
                                        </Input>
                                    }/>
                                    <TranslationProvider k={'PASSWORD'} render={translation =>
                                        <Input>
                                            <InputIcon><Icon name="key"/></InputIcon>
                                            <label htmlFor={'password'}>{translation}</label>
                                            <input id={'password'} onChange={(e) => this.handlePasswordChange(e)}
                                                   value={this.props.loginPassword}
                                                   type='password' name='email'
                                                   placeholder={translation}/>
                                        </Input>
                                    }/>
                                </LoginForm>
                                {this.props.loginFailed ? <ErrorMessage><Translation
                                    k={'EMAIL_OR_PASSWORD_IS_INCORRECT'}/></ErrorMessage> : ''}
                                <Button fullWidth label={<Translation k={'LOGIN'}/>}
                                        callback={() => this.handleLogin()}/>
                            </LoginCard>
                            <Copyright>
                                <Translation k={'COPYRIGHT_TEXT'}/>
                            </Copyright>
                        </LoginColumn>
                    </LoginContainer>
                </div>*/
            )
        } else {
            return (
                <Redirect to={'/'}/>
            )
        }
    }
}


function mapStateToProps(state) {
    return {
        loginEmail: state.getIn(['state', 'loginEmail']),
        loginPassword: state.getIn(['state', 'loginPassword']),
        rememberMe: state.getIn(['state', 'rememberMe']),
        token: state.getIn(['state', 'access_token']),
        loginFailed: state.getIn(['state', 'loginFailed']),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        login: Actions.requestLogin,
        updateLoginEmail: Actions.updateLoginEmail,
        handlePasswordChange: Actions.handlePasswordChange,
        handleRememberMe: Actions.handleRememberMe,
        requestDictionary: Actions.requestDictionary,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(Login)`
  height: 100%;
  width: 100%;
  overflow: auto;
  @media (min-width: 1024px){
    display: flex;
    justify-content: center;
    align-items: center;
  }
`));