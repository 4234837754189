import React, {Component} from 'react';
import styled from 'styled-components';
import {mixins} from "../../mixins";
import StatisticsBlock from "./StatisticsBlock";
import Translation from "../dictionary/Translation";

const SiteImage = styled.div`
  height: 130px;
  width: 130px;
  background-color: ${p => p.theme.cBg};
  margin-right: 20px;
  flex-shrink: 0;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    margin: auto;
    margin-bottom: 20px;
  }
`;

const SiteContent = styled.div`
   >*{
     margin-bottom: 20px;
     &:last-child{
       margin-bottom: 0;
     }
   }
`;

const SiteSelect = styled.div`
    position: relative;
    display: inline-block;
    select{
      cursor: pointer;
      -webkit-appearance: none;
      font-size: ${p => p.theme.sFontHuge}px;
      font-weight: bold;
      border: none;
      outline: none !important;
      padding: 5px 0 5px;
      padding-right: 40px;
      margin-top: 5px;
      margin-left: -5px;
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      option{
        font-size: ${p => p.theme.sFontRegular}px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &:after{
      ${mixins.pseudoToBlock()};
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -9px;
      height: 10px;
      width: 10px;
      border-right: 3px solid black;
      border-bottom: 3px solid black;
      transform: rotate(45deg);
    }
`;

class SiteCard extends Component {
    render() {
        const place = this.props.place;
        const select = this.props.select;
        if (place || select ) {

            return (
                <div className={this.props.className}>
                    <SiteImage>
                        {place.image ? <img src={place.image} alt="Place"/> : ''}
                    </SiteImage>
                    <SiteContent>
                        <StatisticsBlock label={<Translation k={'ADDRESS'}/>}
                                         value={select ? <SiteSelect>{select}</SiteSelect> : place.title}/>
                        {place && place.text ?
                            <StatisticsBlock smallText label={<Translation k={'COMMENTS'}/>}
                                             value={place.text}/> : ''}
                    </SiteContent>
                </div>
            )
        }
        else{
            return <div className={this.props.className}>
                <SiteImage>
                </SiteImage>
                <SiteContent>
                    <StatisticsBlock label={<Translation k={'ADDRESS'}/>}
                                     value={''}/>
                </SiteContent>
            </div>
        }
    }
}

export default styled(SiteCard)`
  display: flex;
  @media (max-width: ${p => p.theme.sBreakpoint}px){
    display: block;
  }
`;