import React, { Component } from "react";

import styled from "styled-components";
import { mixins } from "../../mixins";

import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { toJS } from "../../to-js";
import * as Actions from "../actions/actions";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "../Header";
import MenuColumn from "../MenuColumn";
import Home from "./Home";
import Calendar from "./Calendar";
import Profile from "./Profile";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import Report from "./Report";
import PasswordChange from "./PasswordChange";
import { Inventory } from "./Inventory";
import { StockModal } from "../stock/StockModal";
import StockContactsModal from "../stock/StockContactsModal";
import { StockThankYou } from "../stock/StockThankYou";
import { StockError } from "../stock/StockError";
import { ImageModal } from "../common/ImageModal";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#388a6b",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: 'white',
      },
    },
    MuiPickersDay: {
      day: {
        color: "#6f6f6f",
      },
      selected: {
        backgroundColor: "#388a6b !important",
      },
      current: {
        color: "#30775c",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#388a6b",
      },
    },
  },
});

const Container = styled.div`
  grid-area: c;
  background-color: ${(p) => p.theme.cBg};
  padding: 0 30px;
  overflow: auto;
  @media (max-width: ${(p) => p.theme.sBreakpoint}px) {
    padding: 0 15px;
  }
`;

const Wrapper = styled.div`
  padding: 30px 0;
`;

const Curtain = styled.button`
  background: none;
  padding: 0;
  border: none;
  outline: none;
  grid-area: 1;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 1000;
  opacity: ${(p) => (p.menuOpen ? 1 : 0)};
  visibility: ${(p) => (p.menuOpen ? "visible" : "hidden")};
  ${mixins.ease()};

  @media (max-width: ${(p) => p.theme.sBreakpoint}px) {
    display: block;
  }
`;

class App extends Component {
  componentWillMount() {
    this.props.requestPlaces();
    this.props.requestUser();
  }

  render() {
    const {
      token,
      className,
      menuOpen,
      setMenuState,
      passwordChangeViewed,
      passwordChangeRequired,
      location,
    } = this.props;
    if (token) {
      return (
        <div className={className}>
          {!passwordChangeViewed &&
          passwordChangeRequired &&
          location.pathname !== "/change-password" ? (
            <Redirect to={"/change-password"} />
          ) : (
            ""
          )}
          <MuiThemeProvider theme={materialTheme}>
            <Header />
            <MenuColumn />
            <Container>
              <Wrapper>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/report" component={Report} />
                  <Route path="/calendar" component={Calendar} />
                  <Route path="/change-password" component={PasswordChange} />
                  <Route path="/inventory" component={Inventory} />
                  <Route component={Profile} />
                </Switch>
              </Wrapper>
            </Container>
            <Curtain menuOpen={menuOpen} onClick={() => setMenuState(false)} />
            <Route exact path="/inventory/stock" component={StockModal} />
            <Route
              exact
              path="/inventory/form"
              component={StockContactsModal}
            />
            <Route exact path="/inventory/thankyou" component={StockThankYou} />
            <Route exact path="/inventory/error" component={StockError} />
            <ImageModal />
          </MuiThemeProvider>
        </div>
      );
    } else {
      return <Redirect to={"/login"} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    token: state.getIn(["state", "access_token"]),
    passwordChangeViewed: state.getIn(["state", "passwordChangeViewed"]),
    passwordChangeRequired: state.getIn(["profile", "password_change"]),
    menuOpen: state.getIn(["state", "menuOpen"]),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestPlaces: Actions.requestPlaces,
      requestUser: Actions.requestUser,
      setMenuState: Actions.setMenuState,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  toJS(styled(App)`
    position: relative;
    height: 100%;
    display: grid;
    grid-template-areas: "h h" "m c";
    grid-template-rows: 80px auto;
    grid-template-columns: auto 1fr;
    a {
      text-decoration: none;
    }
    @media (max-width: ${(p) => p.theme.sBreakpoint}px) {
      grid-template-areas: "h h" "c c" "m m";
      grid-template-rows: 80px 1fr auto;
      ${mixins.ease()};
      transform: ${(p) => (p.menuOpen ? "translateX(-260px)" : "none")};
    }
  `)
);
