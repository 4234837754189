import React, {Component} from 'react';

import styled from 'styled-components'

class H extends Component {
    /*constructor(props) {
        super(props);
    }*/

    render() {
        const Heading = "h" + this.props.size;
        return (
            <Heading className={this.props.className}>
                {this.props.children}
            </Heading>
        )
    }
}

export default styled(H)`
  margin: 0;
  font-size: ${p => p.theme['sH' + p.size] ? p.theme['sH' + p.size] + 'px' : 'inherit'}; //automatic h font size from theme
`;