import React, {Component} from 'react';

import styled from 'styled-components'
import {mixins} from "../../mixins";
import {Manager, Reference, Popper} from 'react-popper';
import moment from "moment";
import onClickOutside from "react-onclickoutside";
import Button from "../common/Button";
import Translation from "../dictionary/Translation";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as Actions from "../actions/actions";
import {Redirect} from "react-router";

const Label = styled.div`
  position: relative;
`;

const Popover = styled.div`
  background-color: ${p => p.theme.cTheme};
  padding: 15px;
  border-radius: ${p => p.theme.sRadius}px;
  color: ${p => p.theme.cWhite};
  font-size: 12px;
  position: relative;
  z-index: 200;
`;

const PopoverHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const PopoverContainer = styled.div`
  margin-bottom: 15px;
  &:last-child{
    margin-bottom: 0;
  }
`;

const PopoverActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &:last-child{
    margin-right: 0;
  }
  >*{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
`;

class Day extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            redirect: false,
        };
        this.popover = React.createRef();

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(prevState => ({
            isOpen: prevState.isOpen || true,
        }))
    }

    componentWillUnmount() {
        this.setState({
            redirect: false,
        });
    }

    handleClickOutside = evt => {
        this.setState({
            isOpen: false,
        })
    };

    render() {
        const reports = this.props.reports;
        //console.log(reports);

        if (reports.length) {
            const reportsToRender = reports.map((report, key) => {
                const startTime = moment(report.start).format('H:mm');
                const endTime = moment(report.end).format('H:mm');
                const lunchStartTime = moment(report.lunch_start).format('H:mm');
                const lunchEndTime = moment(report.lunch_end).format('H:mm');
                let duration;
                let lunchTime = '';
                if (report.lunch_start) {
                    duration = moment.duration(moment(report.lunch_start).diff(moment(report.start))).add(
                        moment.duration(moment(report.end).diff(moment(report.lunch_end)))
                    );
                    lunchTime = (
                        <div>
                            {<span>
                            <Translation k={'LUNCH_SHORT'}/><span>{': ' + lunchStartTime + ' - ' + lunchEndTime}</span>
                        </span>}
                        </div>
                    )
                } else {
                    duration = moment.duration(moment(report.end).diff(moment(report.start)));
                }
                const formatedDuration = duration.hours() + ' h ' + (duration.minutes() ? duration.minutes() + ' min' : '');

                const deadline = moment(moment(new Date()).format('YYYY-MM-DD')).add(-2, 'day');
                const day = moment(moment(moment(report.start)));

                const isEditable = day.isSameOrAfter(deadline, 'days');
                const difference = day.diff(deadline, 'days') - 2;

                const deleteReport = () => {
                    this.props.deleteReport(report.id);
                };
                const changeDay = () => {
                    if (isEditable) {
                        this.props.changeDay(difference);
                        this.setState({
                            redirect: true,
                        });
                    }
                };
                return <React.Fragment key={key}>
                    <PopoverHeader>{formatedDuration}</PopoverHeader>
                    <PopoverContainer>
                        <div>
                            {startTime + ' - ' + endTime}
                        </div>
                        {lunchTime}
                    </PopoverContainer>
                    {isEditable ? <PopoverActions>
                        <Button callback={changeDay} capitalize
                                label={<Translation k={'EDIT'}/>} small dark/>
                        <Button callback={deleteReport} capitalize
                                label={<Translation k={'REMOVE'}/>} small dark/>
                    </PopoverActions> : ''}
                </React.Fragment>

            });

            if (this.state.redirect) {
                return <Redirect to={'/report'}/>
            } else {
                return (
                    <Manager>
                        <Reference>
                            {({ref}) => (
                                <div ref={ref} onClick={this.handleClick} className={this.props.className}>
                                    <Label>{this.props.label}</Label>
                                </div>
                            )}
                        </Reference>
                        {(reports.length && this.state.isOpen) && (
                            <Popper placement="auto"
                                    innerRef={(node) => this.popperNode = node}
                                    eventsEnabled={true}
                                    positionFixed={false}
                                    modifiers={{
                                        preventOverflow: {
                                            enabled: true,
                                            padding: 5,
                                            priority: ['top', 'right', 'left', 'bottom']
                                        }
                                    }}>
                                {({ref, style, placement, arrowProps}) => {
                                    const newStyle = {...style, zIndex: 200};
                                    return <div ref={ref} style={newStyle} data-placement={placement}>
                                        <Popover ref={this.popover} className={'ignore-react-onclickoutside'}>
                                            {reportsToRender}
                                        </Popover>
                                        <div ref={arrowProps.ref} style={arrowProps.style}/>
                                    </div>
                                }}
                            </Popper>
                        )}
                    </Manager>
                )
            }
        } else {
            return <div className={this.props.className}><Label>{this.props.label}</Label></div>
        }
    }
}


function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeDay: Actions.changeSelectedReportDay,
        deleteReport: Actions.deleteReport,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(onClickOutside(Day, {eventTypes: ["click", "touchend"]}))`
    padding: 10px 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: ${p => {
    if (p.current) {
        return p.theme.cWhite;
    } else if (p.weekend) {
        return p.theme.cWeekend;
    }
}};
    font-weight: ${p => p.current ? 'bold' : 'normal'};
    position: relative;
    cursor: pointer;
    ${mixins.ease()}
    &:before{
      display: block;
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: ${p => {
    if (p.current) {
        return `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23${p.theme.cTheme.substr(1)}'/%3E%3C/svg%3E%0A")`;
    }
    if (p.reports.length) {
        return `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23${p.theme.cBorder.substr(1)}'/%3E%3C/svg%3E%0A")`;
    }
}};
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    &:hover, &:focus{
      &:before{
        background-image: ${p => {
    if (p.current) {
        return `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23${p.theme.cThemeActive.substr(1)}'/%3E%3C/svg%3E%0A")`;
    } else if (p.reports.length) {
        return `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23${p.theme.cDateHover.substr(1)}'/%3E%3C/svg%3E%0A")`;
    } else {
        return `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23${p.theme.cBorder.substr(1)}'/%3E%3C/svg%3E%0A")`;
    }
}};
      }
    }
`));