import {fromJS} from 'immutable';

const defState = fromJS({

});

export default function reducer(state = defState, action) {
    switch (action.type) {
        case 'DICTIONARY_LOAD_SUCCESS':{
            return action.payload.get('dictionary');
        }
        default:
            return state;
    }
};