import { applyMiddleware, createStore,compose} from "redux";

// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware'
import {persistToken} from './middleware/persistToken'
import {apiCall} from './middleware/apiCall'
import reducers from "./reducers";

const middlewareArray = [
    apiCall, persistToken, promise(), thunk,
];

// if (process.env.NODE_ENV === 'development') {
//     middlewareArray.push(createLogger());
// }

const middleware = applyMiddleware(...middlewareArray);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(reducers, composeEnhancers(middleware));