import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectModalImage } from "../../selectors/imageModalSelectors";
import styled from "styled-components";
import { setImageModal } from "../actions/actions";
import { mixins } from "../../mixins";

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
`;

const ActiveZone = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${(p) => p.theme.cInactiveText};
  opacity: 0.9;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  ${mixins.ease()};
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 2px;
    background-color: ${(p) => p.theme.cBlack};
    width: 20px;
    margin-left: -10px;
    margin-top: -1px;
    border-radius: 1px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: ${(p) => p.theme.cInput};
  }
`;

export const ImageModal = () => {
  const dispatch = useDispatch();
  const image = useSelector(selectModalImage);

  const handleClose = () => {
    dispatch(setImageModal(null));
  };

  if (!image) return "";
  return (
    <Wrapper>
      <Backdrop />
      <ImageContainer>
        <img
          src={image.url}
          style={{ width: image.width, height: image.height }}
          alt={""}
        />
      </ImageContainer>
      <ActiveZone onClick={handleClose} />
      <CloseButton onClick={handleClose} />
    </Wrapper>
  );
};
