import axios from "axios";
import {fromJS} from 'immutable';

// const API_ROOT = 'http://bj-app.maxplanck.company/api/v1/';
//const API_ROOT = 'http://api.baronajumti.lv/api/v1/';

/*
This below is the format the middleware accepts.
{
  types: [ACT_LOGIN_PENDING, ACT_LOGIN_SUCCESS, ACT_LOGIN_ERROR],
  url: '/auth/login',
  method: 'post',
  query: {'ajax': true},
  data: {username, password},
  dataType: 'json'
}
*/

export const apiCall = store => next => action => {
    if (typeof action.types === 'undefined' || typeof action.url === 'undefined') return next(action);

    const [pendingType, successType, errorType] = action.types;

    let {
        url,
        baseURL,
        method = 'get',
        data = {},
        toDispatch = []
    } = action;

    next({
        type: pendingType,
        payload: '',
    });

    axios({
        url: url,
        method: method,
        baseURL: baseURL,
        data: data,
        headers: {
            Authorization: "Bearer " + store.getState().getIn(['state', 'access_token']),
        }
    })
        .then(r => {
                next({
                    type: successType,
                    payload: fromJS(r.data)
                });
                for (let index in toDispatch) {
                    next(toDispatch[index]);
                }
            }
        )
        .catch(function (e) {
                if (typeof (e) !== 'undefined') {
                    if (typeof (e.response) !== 'undefined') {
                        if (e.response.status === 401) {
                            localStorage.removeItem('TOKEN');
                            next({
                                type: 'LOGOUT',
                                payload: '',
                            })
                        } else {
                            next({
                                type: errorType,
                                payload: fromJS(e)
                            });
                        }
                    }
                }
            }
        );
};