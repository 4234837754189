import React, { Component } from "react";
import { withRouter } from "react-router";

import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "./common/Button";
import Translation from "./dictionary/Translation";
import { bindActionCreators } from "redux";
import * as Actions from "./actions/actions";
import connect from "react-redux/es/connect/connect";
import { toJS } from "../to-js";
import Icon from "./common/Icon";
import { mixins } from "../mixins";

const LogoutButton = styled.button`
  padding: 25px ${(p) => p.theme.sCardPadding + 12}px;
  text-align: left;
  border: none;
  border-top: 1px solid ${(p) => p.theme.cBg};
  background: none;
  cursor: pointer;
  font-size: ${(p) => p.theme.sFontButton}px;
  font-weight: bold;
  color: ${(p) => p.theme.cSecondaryText};
  outline: none;
  fill: ${(p) => p.theme.cSecondaryText};
  display: flex;
  align-items: center;
  ${mixins.ease()};
  &:hover,
  &:focus {
    color: ${(p) => p.theme.cTheme};
    fill: ${(p) => p.theme.cTheme};
  }
  @media (max-width: ${(p) => p.theme.sBreakpoint}px) {
    padding: 12px 25px;
    border-top: none;
    color: ${(p) => p.theme.cWhite};
    position: relative;
    margin-top: 20px;

    &:hover,
    &:focus {
      color: ${(p) => p.theme.cWhite};
      fill: ${(p) => p.theme.cWhite};
      background-color: rgba(255, 255, 255, 0.2);
    }

    svg {
      path {
        fill: ${(p) => p.theme.cWhite};
      }
    }

    &:before {
      ${mixins.pseudoToBlock()};
      position: absolute;
      height: 1px;
      left: 25px;
      right: 25px;
      top: -13px;
      background-color: ${(p) => p.theme.cWhite};
    }

    span {
      //display: none;
    }
  }
`;

const LogoutIcon = styled.div`
  margin-right: 20px;
  @media (max-width: ${(p) => p.theme.sBreakpoint}px) {
    //margin: 0;
  }
`;

class Menu extends Component {
  /*constructor(props) {
        super(props);
    }*/

  render() {
    const { location, className, setMenuState, logout } = this.props;
    const pathname = location.pathname;
    return (
      <div className={className}>
        <ul>
          <li>
            <Link to={"/"} onClick={() => setMenuState(false)}>
              <Button
                menu
                fullWidth
                icon={"summary"}
                alignLeft
                tag={"div"}
                label={<Translation k={"SUMMARY"} />}
                secondary={true}
                active={pathname === "/"}
              />
            </Link>
          </li>
          <li>
            <Link to={"/report"} onClick={() => setMenuState(false)}>
              <Button
                menu
                fullWidth
                icon={"add"}
                alignLeft
                tag={"div"}
                label={<Translation k={"SUBMIT_WORK_REPORT"} />}
                secondary={true}
                active={pathname === "/report"}
              />
            </Link>
          </li>
          <li>
            <Link to={"/calendar"} onClick={() => setMenuState(false)}>
              <Button
                menu
                fullWidth
                icon={"calendar"}
                alignLeft
                tag={"div"}
                label={<Translation k={"WORK_ACCOUNTING_CALENDAR"} />}
                secondary={true}
                active={pathname === "/calendar"}
              />
            </Link>
          </li>
          <li>
            <Link to={"/inventory"} onClick={() => setMenuState(false)}>
              <Button
                menu
                fullWidth
                icon={"inventory"}
                alignLeft
                tag={"div"}
                label={<Translation k={"MY_INVENTORY"} />}
                secondary={true}
                active={pathname.includes("/inventory")}
              />
            </Link>
          </li>
          <li>
            <Button
              menu
              fullWidth
              icon={"bus"}
              alignLeft
              disabled
              tag={"div"}
              label={<Translation k={"SUBMIT_FUEL_REPORT"} />}
              secondary={true}
            />
          </li>
          <li>
            <Link to={"/profile"} onClick={() => setMenuState(false)}>
              <Button
                menu
                fullWidth
                icon={"profile"}
                alignLeft
                tag={"div"}
                label={<Translation k={"MY_PROFILE"} />}
                secondary={true}
                active={
                  pathname === "/profile" || pathname === "/change-password"
                }
              />
            </Link>
          </li>
        </ul>
        <LogoutButton onClick={logout}>
          <LogoutIcon>
            <Icon name={"logout"} />
          </LogoutIcon>
          <span>
            <Translation k={"LOGOUT"} />
          </span>
        </LogoutButton>
      </div>
    );
  }
}

const StyledMenu = styled(Menu)`
  grid-area: m;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ul {
    list-style: none;
    padding: 0 ${(p) => p.theme.sCardPadding}px;
    li {
      padding-bottom: ${(p) => p.theme.sCardPadding / 2}px;
    }
  }
  @media (max-width: ${(p) => p.theme.sBreakpoint}px) {
    grid-area: 1;
    height: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    width: 260px;
    background: #0f0f0f;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 0 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    justify-content: flex-start;
    padding-top: 20px;
    box-sizing: border-box;
    ul {
      margin: 0;
      //display: flex;
      //align-items: center;
      padding: 0;
      //li{
      //  padding-bottom: 0;
      //}
    }
  }
`;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: Actions.logout,
      setMenuState: Actions.setMenuState,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(toJS(StyledMenu))
);
