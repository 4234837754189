import React, {Component} from 'react';
import styled from 'styled-components';
import {mixins} from "../../mixins";
import uuid from 'uuid/v1';

const Label = styled.label`
  margin-bottom: 10px;
  display: block;
  font-size: ${p => p.theme.sFontSmall}px;
`;

class Input extends Component {
    render() {
        const uid = 'input-' + uuid();
        return (
            <React.Fragment>
                {this.props.label ? <Label htmlFor={uid}>{this.props.label}</Label> : ''}
                <input id={uid} {...this.props} className={this.props.className}/>
            </React.Fragment>
        );
    }
}

export default styled(Input)`
  border: none;
  height: 40px;
  border-radius: ${p => p.theme.sRadius}px;
  background-color: ${p => p.theme.cInput};
  display: block;
  padding: 0 20px;
  flex-grow: 1;
  margin-right: 10px;
  font-weight: bold;
  outline: none !important;
  ${mixins.ease()};
  width: 100%;
  box-sizing: border-box;
  &:focus{
    box-shadow: 0 0 0 2px rgba(56, 138, 107, 0.3);
  }
`;