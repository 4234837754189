import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toJS } from "../to-js";
import * as Actions from "./actions/actions";

import Login from "./pages/Login";
import App from "./pages/App";
import Loader from "./common/Loader";
import { mixins } from "../mixins";

const GlobalLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(p) => p.theme.cBg};
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(p) => (p.dead ? 0 : 1)};
  visibility: ${(p) => (p.dead ? "hidden" : "visible")};
  pointer-events: ${(p) => (p.dead ? "none" : "auto")};
  ${mixins.ease()};
`;

class Layout extends Component {
  constructor(props) {
    super(props);

    this.props.init();
    this.props.requestDictionary();

    this.ifLoading = this.ifLoading.bind(this);
  }

  ifLoading() {
    for (let event in this.props.loading) {
      if (this.props.loading[event]) {
        return true;
      }
    }
    return false;
  }

  render(history) {
    const isLoading = this.ifLoading();
    return (
      <div className={this.props.className}>
        <GlobalLoading dead={!isLoading}>
          <Loader />
        </GlobalLoading>
        <Router history={history}>
          <Switch>
            <Route exact path={"/login"} component={Login} />
            <Route path={"/"} component={App} />
          </Switch>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    image: state.getIn(["profile", "image"]),
    loading: state.getIn(["state", "loading"]),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      init: Actions.init,
      requestDictionary: Actions.requestDictionary,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  toJS(styled(Layout)`
    height: 100%;

    @media (max-width: ${(p) => p.theme.sBreakpoint}px) {
      overflow: hidden;
    }
  `)
);
