export const selectProfileLoading = (state) => {
  return state.getIn(["state", "profileLoading"]);
};

export const selectStockLoading = (state) => {
  return state.getIn(["state", "stockLoading"]);
};

export const selectLanguage = (state) => {
  return state.getIn(["state", "selectedLanguage"]);
};
