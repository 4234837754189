import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import styled from "styled-components";
import { toJS } from "../../to-js";
import Modal from "../common/Modal";
import Translation from "../dictionary/Translation";
import Button from "../common/Button";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import Loader from "../common/Loader";
import * as Actions from "../actions/actions";
import { Redirect } from "react-router";

const InputBlock = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Infotext = styled.p`
  font-size: ${(p) => p.theme.sFontSmall}px;
  color: ${(p) => p.theme.cTheme};
  font-weight: bold;
  margin-bottom: 20px;
`;

class StockContactsModal extends Component {
  handleMessageChange = (e) => {
    this.props.stockMessageChange(e.target.value);
  };

  render() {
    const { success, error, message } = this.props;
    if (success) {
      return <Redirect to={"/inventory/thankyou"} />;
    }
    if (error) {
      return <Redirect to={"/inventory/error"} />;
    }
    return (
      <Modal
        closeLink={"/inventory"}
        form
        small
        onSubmit={(e) => {
          e.preventDefault();
          this.props.sentStockMessage({ text: message });
        }}
        title={<Translation k={"SEND_A_MESSAGE"} />}
        rightActions={
          <React.Fragment>
            <Button
              secondary
              to={"/inventory"}
              label={<Translation k={"CANCEL"} />}
            />
            <Button type={"submit"} label={<Translation k={"SEND"} />} />
          </React.Fragment>
        }
      >
        {!this.props.profileLoading ? (
          <React.Fragment>
            <Infotext>
              <Translation k={"DESCRIBE_YOUR_MESSAGE_TEXT"} />
            </Infotext>
            <InputBlock>
              <Input
                disabled
                type={"email"}
                label={<Translation k={"EMAIL"} />}
                value={this.props.profile.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </InputBlock>
            <InputBlock>
              <Textarea
                label={<Translation k={"DESCRIPTION"} />}
                value={message}
                onChange={(e) => this.handleMessageChange(e)}
              />
            </InputBlock>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.get("profile"),
    success: state.getIn(["stock", "success"]),
    error: state.getIn(["stock", "error"]),
    profileLoading: state.getIn(["state", "profileLoading"]),
    message: state.getIn(["stock", "message"]),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      stockMessageChange: Actions.stockMessageChange,
      sentStockMessage: Actions.sentStockMessage,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(StockContactsModal));
